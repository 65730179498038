aside.size-select {
  @include position(fixed, 60px 0% 0% 50%);
  @include transform(translateX(-50%));
  background-color: $roughwood-grey-dark;
  width: 100vw;
  height: calc(100vh - 60px);
  z-index: 11;
  display: none;
  overflow-y: auto;
  outline: 999px solid rgba(0,0,0,0.25);
  &.show {
    display: block;
  }
  .close-button {
    top: 10px;
    right: 10px;
    span {
      display: none;
    }
    &:before,
    &:after {
      background-color: $roughwood-brown;
    }
  }
  .description .product-description {
    padding-bottom: 0;
  }
  .product-bullets p {
    color: $roughwood-brown;
  }
  .product-bullets li {
    margin: 8px 0;
  }
  @media all and (min-width: 800px) {
    width: 85vw;
  }
}