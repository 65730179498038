.product {
  width: 100%;
  background-color: $roughwood-grey-dark;
  overflow-x: hidden;
  overflow-y: hidden;
  .filter {
    background-color: rgba(#000, 0.75);
  }
}
.drift-zoom-pane {
  background-color: $roughwood-grey-dark;
  z-index: 10;
}
.breadcrumbs-container {
  height: 30px;
  width: 100%;
  position: relative;
  top: 65px;
  margin: 1em 0;
  margin-left: 1em;
  z-index: 1;
}
.breadcrumbs {
  list-style-type: none;
  width: 100%;
  padding-left: 0;
  li {
    display: inline;
    font-family: $font-roughwood;
    color: $roughwood-brown;
    font-size: 1.6em;
  }
}
.product-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7em;
}

.image-box-wrap {
  position: relative;
  overflow: auto;
}
.image-container {
  width: 100%;
}
.image-box {
  width: 100%;
  opacity: 1;
  .product-image-container {
    width: 100%;
    float: left;
    overflow-y: hidden;
    position: relative;
  }
  .product-image {
    width: 70%;
    height: auto;
    float: left;
    @include position(relative, auto auto auto 50%);
    @include transform(translateX(-50%));
    &.zoom-active {
      opacity: 0;
    }
  }
}

.hover-container {
  height: 3em;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    width: 3em;
    height: 3em;
    margin-left: 0.75em;
  }
}

.hover-tip {
  color: $roughwood-brown;
  font-family: $font-body;
  font-size: 1.3em;
  line-height: 2em;
}
.return-button {
  @include appearance(none);
  background: transparent;
  outline: none;
  border: none;
  @include position(absolute, 60px 10px auto auto);
  font-family: $font-roughwood;
  font-size: 18px;
  color: $product-background;
  display: none;
}
.product-information {
  width: 100%;
}
.product,
aside.size-select {
  .description {
    color: #fff;
    font-family: $font-body;
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2em;
    > li {
      padding: 1em;
    }
    .price {
      font-size: 2.8em;
      width: 100%;
      color: $roughwood-brown;
    }
    .product-name {
      padding-bottom: 0;
    }
    .sale-price {
      text-decoration: line-through;
    }
    h1 {
      font-size: 4em;
      color: $roughwood-brown;
      margin: 0;
    }
    .designer-name {
      color: $product-background;
      padding: 0;
      p {
        color: inherit;
        font-size: 1.6em;
      }
    }
  }
  .colors {
    height: auto;
    width: 100%;
    padding: 10px 0;
    > p {
      font-family: $font-roughwood;
      font-size: 2.4em;
      color: $roughwood-brown;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 1.5em;
    }
    .color-swatch {
      height: 40px;
      width: 40px;
      background: $roughwood-brown;
      margin: 5px 3px;
      float: left;
      cursor: pointer;
      position: relative;
      list-style-type: none;
      border-radius: 50%;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.26);
      @include transition-duration(0.15s);
      a {
        @include position(absolute, 0px 0px 0px 0px);
      }
    }
    .color-swatch:nth-of-type(1) {
      margin-left: 0;
    }
    .active {
      border: 2px solid $light;
    }
  }
  .size {
    margin-top: 0;
    line-height: 40px;
    width: 100%;
    padding: 10px 0;
    > p {
      font-size: 2.4em;
      color: $roughwood-brown;
      font-family: $font-roughwood;
    }
    ul {
      width: 100%;
      li {
        height: 100%;
        list-style-type: none;
        width: auto;
        display: inline;
        font-size: 20px;
        height: 40px;
        text-align: center;
        cursor: pointer;
        line-height: 40px;
        color: $product-background;
        margin: 0 5px;
        @extend %default-transition;
        float: left;
      }
      li.circle {
        border-radius: 50%;
        border: 2px solid $product-background;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.26);
        width: 40px;
        position: relative;
        span {
          @extend %absolute-middle;
        }
      }
      li:nth-of-type(1) {
        margin-left: 0;
      }
    }
    .circle.active,
    .active {
      color: $roughwood-grey;
      border-color: $roughwood-grey;
    }
    .circle.no-inventory {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.product-description {
  width: 100%;
  font-size: 2.4em;
  margin-left: 0;
  color: $roughwood-brown;
  font-family: $font-roughwood;
  .product .description & {
    padding: 0.25em;
  }
}
.product-bullets {
  width: 100%;
  font-size: 2em;
  color: $roughwood-brown;
  padding-bottom: 1.5em;
  .product .description & {
    padding: 0.25em;
  }
  ul {
    li {
      margin: 8px 0;
      width: 100%;
      p {
        color: $roughwood-brown;
      }
    }
  }
}
.purchase-container {
  width: 100%;
  margin-top: 10px;
  @extend %default-transition;
  display: flex;
  flex-direction: column;
  align-items: center;
  > form  {
    width: 85%;
    > button.cta {
      width: 100%;
    }
  }
  .show-related:nth-of-type(2) {
    margin-top: 0px;
  }
}

.purchase-button {
  font-size: 16px;
  display: block;
  line-height: 1;
  color: $roughwood-brown;
  background: transparent;
  font-family: $font-body;
  transition-duration: 0.3s;
  cursor: pointer;
  position: relative;
  &.cta {
    color: $roughwood-brown;
    border-color: $roughwood-brown;
    line-height: 25px;
    margin: 0.5em 0;
    width: 85%;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
.sizing-chart {
  text-decoration: underline;
  color: $roughwood-brown;
  line-height: 1;
  margin-right: auto;
  span {
    &:after {
      content:"";
    }
  }
}
.notification-tag {
  @include position(absolute, 50% auto auto 130%);
  @include transform(translateY(-50%));
  font-family: $font-body;
  color: $roughwood-grey;
  width: 100px;
}
.size {
  .notification-tag {
    @include position(relative, auto auto auto auto);
    @include transform(none);
    font-size: 16px;
    float: left;
  }
}
.show-related {
  @include appearance(none);
  outline: none;
  border: none;
  font-size: 18px;
  display: block;
  line-height: 32px;
  width: 100%;
  color: $product-background;
  font-family: $font-body;
  float: left;
  background: transparent;
  text-align: left;
  transition-duration: 0.3s;
  cursor: pointer;
  margin-top: 20px;
  @extend %default-transition;
  span {
    position: relative;
    float: left;
    &:after {
      content: '+';
      @include position(absolute, 50% -10% auto auto);
      @include transform(translateY(-50%));
    }
  }
}
.icon-filler-2 {
  width: 80%;
  max-height: 65px;
  display: block;
  margin: 0 auto;
  use {
    fill: $product-background;
  }
}
.related-products {
  h2 {
    font-family: $font-roughwood;
    color: #fff;
    background: $roughwood-brown;
    font-size: 16px;
    width: 100%;
  }
  h3 {
    font-family: $font-roughwood;
    color: #fff;
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    width: 100%;
    padding: 0 5%;
    @extend %absolute-middle;
  }
}
@media all and (min-width: 600px) {
  .related-products {
    h2 {
      font-size: 20px;
    }
  }
}
@media all and (min-width: 800px) {
  .breadcrumbs {
    li {
      font-size: 20px;
    }
  }
  .product-container {
    flex-direction: row;
  }
  .image-container {
    width: 45%
  }
  .product-information {
    width: 55%;
  }
  .image-box {
    max-height: 600px;
    .product-image {
      width: auto;
      height: auto;
      max-height: 600px;
    }
  }
  .product {
    min-height: 100vh;
    .description {
      padding: 0 10%;
    }
  }
}
.no-touchevents {
  .show-related:hover {
    color: $roughwood-grey;
  }
  .purchase-button:hover {
    color: $roughwood-grey;
  }
  .related-products {
    .looks-modal .close-items:hover {
      background-color: $roughwood-grey-dark;
    }
  }
}
div::-webkit-scrollbar {
  display: none;
}
