a.script {
  font-family: $font-body;
  color: inherit;
  span {
    position: relative;
    &:after {
      content: '';
      @include position(absolute, auto auto -6px 0px);
      @extend %default-transition;
      width: 0%;
      height: 2px;
      background-color: $roughwood-brown;
    }
  }
}

.no-touchevents {
  a.script:hover {
    span:after {
      width: 100%;
    }
  }
}
