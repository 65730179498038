main.scroll {
    padding-top: 60px;
}
.scroll {
    width: 100%;
    float: left;
    position: relative;
    padding-bottom: 100px;
    background-color: $roughwood-grey-dark;
    z-index: 1;
    min-height: 100vh;
    &:after {
        content: "";
        width: 100%;
        height: 2000px;
        background-color: $roughwood-grey-dark;
        @include position(fixed, 0 auto auto 0);
        z-index: -10;
    }
    .icon-roughwood-tree {
        width: 100%;
        height: 100vh;
        @extend %absolute-middle;
        position: fixed;
        z-index: 0;
        use {
            width: 100%;
            fill: rgba($roughwood-brown, 0.085);
        }
    }
    &.filterable {
        padding-top: 120px;
        .product-container-scroll {
            width: 100%;
            float: left;
        }
        &:after {
            display: none;
        }
        .icon-roughwood-tree {
            height: 45vh;
            position: absolute;
        }
    }
}
.product-container-scroll {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media all and (min-width: 600px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        li {
            width: 47.5%;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                margin-top: 1em;
            }
        }
    }
    @media all and (min-width: 960px) {
        li {
            width: 31%;
        }
    }
    @media all and (min-width: 1100px) {
        li {
            width: 23%;
        }
    }
}
.loader-container {
    width: 0;
    height: 0;
    background: rgba($dark, 0.4);
    @include position(fixed, 50% auto auto 50%);
    @include transform(translateX(-50%) translateY(-50%));
    @include transition(0.15s);
    overflow: hidden;
    z-index: 1000;
    svg {
        width: 200px;
        height: auto;
        @extend %absolute-middle;
    }
    .st1 {
        stroke-dasharray: 2000;
        stroke-alignment: inside;
        fill: $dark;
        stroke: $product-background;
        stroke-width: 10px;
        stroke-linecap: round;
        stroke-linejoin: round;
        @include animation(dash 4s linear infinite);
        @include keyframes(dash){
            to {
                stroke-dashoffset: 4000;
            }
        }
    }
    .st0 {
        fill: transparent;
        stroke: $dark;
        stroke-width: 10px;
    }
}
.show-loader {
    height: 100vh;
    width: 100%;
}
.explain-container {
    width: 100%;
    height: 100vh;
    display: none;
    @include position(fixed, 0px auto auto 0px);
    background: rgba($dark, 0.4);
    z-index: 1000;
}
.explain-modal {
    background: $product-background;
    width: 80%;
    max-width: 400px;
    padding: 2.5%;
    padding-top: 1%;
    @extend %absolute-middle;
    top: 25%;
    svg {
        width: 20%;
        max-width: 100px;
        fill: $roughwood-brown;
        display: block;
        margin: 0 auto;
        margin-bottom: 5%;
    }
    h2 {
        font-size: 18px;
        line-height: 24px;
        color: $roughwood-grey;
        font-family: $font-body;
    }
}
.show-explain {
    display: block;
}
.close-explain {
    @include appearance(none);
    @include position(absolute, 0px 0px auto auto);
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    @extend %default-transition;
    &:after {
        content: "";
        height: 26px;
        width: 2px;
        background: $roughwood-grey;
        @include position(absolute, 50% auto auto 50%);
        @include transform(translateY(-50%) translateX(-50%) rotate(45deg));
        @extend %default-transition;
    }
    &:before {
        content: "";
        height: 26px;
        width: 2px;
        background: $roughwood-grey;
        @include position(absolute, 50% auto auto 50%);
        @include transform(translateY(-50%) translateX(-50%) rotate(-45deg));
        @extend %default-transition;
    }
}
.no-touchevents {
    .scroll {
        .product-container-scroll {
            li:hover {
                .name {
                    opacity: 1;
                }
                .price {
                    opacity: 1;
                }
            }
        }
    }
    .close-explain:hover {
        &:after,
        &:before {
            background: $roughwood-brown;
        }
    }
}
.touchevents {
    .scroll {
        li {
            .name {
                opacity: 1;
            }
            .price {
                opacity: 1;
            }
        }
    }
}
