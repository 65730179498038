// // // // // // // // // // //
//
// Lifestyle Entry
//
// // // // // // // // // // //

.lifestyle-entry {
  width: 100%;
  float: left;
  margin-top: 60px;
}
