.home {
    width: 100%;
    @include clearfix();
    position: relative;
    float: left;
    overflow-x: hidden;
}
.scroll-down {
    height: 35px;
    width: 20px;
    background: rgba($roughwood-brown, 0.6);
    border: 2px solid $roughwood-grey;
    @include position(fixed, auto auto 3.5% 50%);
    @include transform(translateX(-50%));
    @include appearance(none);
    outline: none;
    border-radius: 10px;
    z-index:9;
    opacity: 0.85;
    @include transition(0.15s);
    &:after {
        content: "";
        width: 3px;
        height: 3px;
        background-color: $roughwood-grey;
        @include position(absolute, 7.5px auto auto 50%);
        @include transform(translateX(-50%));
        @include animation(mouse-wheel 2.5s linear);
        @include animation-iteration-count(infinite);
    }
}
.hide-button {
    opacity: 0;
    pointer-events: none;
}
.story-section {
    width: 100%;
    float: left;
    background: $roughwood-brown;
    padding-bottom: 5%;
    position: relative;
    z-index: 10;
    overflow-x: hidden;
}
.story-section:last-of-type {
    padding-bottom: 0;
}
.second {
    margin-top: 50vh;
}
.text-container {
    h2 {
        color: $roughwood-grey;
        font-size: 20px;
        width: 100%;
        text-align: center;
        font-family: $font-roughwood;
        padding: 0.5% 0;
        padding-top: 20px;
    }
    .icon-line-8 {
        width: 80%;
        max-width: 400px;
        height: 60px;
        display: block;
        margin: 0 auto;
        use {
            fill: $product-background;
        }
    }
    blockquote {
        width: 100%;
        padding: 5%;
        color: $roughwood-grey;
        font-family: $font-body;
        font-size: 18px;
        line-height: 24px;
    }
}
.link-container {
    width: 100%;
    float: left;
    max-width: 40em;
    @extend %center;
    margin: 4em 0;
    button.navigate {
        font-family: $font-body;
        font-size: 1.6em;
        width: auto;
        color: $roughwood-grey;
        background-color: transparent;
        border: 1px solid $roughwood-grey;
        float: left;
        width: 40%;
        margin: 0 5%;
        padding: 1em;
        @extend %default-transition;
    }
    &.single {
        button.navigate {
            width: 40%;
            margin: 0 30%;
        }
    }
}
.no-touchevents {
    .link-container {
        button.navigate:hover {
            background-color: $roughwood-grey;
            color: $roughwood-brown;
        }
    }
}
.photo-container {
    width: 100%;
    float: left;
    .image {
        width: 50%;
        float: left;
        padding-bottom: 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
@media all and (min-width: 650px) {
    .photo-container {
        .image {
            width: 25%;
            padding-bottom: 25%;
        }
    }
}
@media all and (min-width: 683px) {
    .home {
        .autoplay {
            div {
                h2 {
                    font-size: 24px;
                }
            }
        }
    }
    .story-section {
        h2 {
            font-size: 24px;
        }
    }
    .photo-container {
        .image {
            display: block;
        }
    }
}
@media all and (min-width: 800px) {
    .home {
        .autoplay {
            div {
                h2 {
                    font-size: 32px;
                }
            }
        }
    }
    .text-container {
        button {
            font-size: 28px;
        }
        a p {
            font-size: 20px;
        }
    }
    .story-section {
        h2 {
            font-size: 42px;
        }
        blockquote {
            padding: 1% 15%;
        }
    }
}
@media all and (min-width: 1000px) {
    .photo-container {
        .image {
            width: 25%;
            padding-bottom: 25%;
        }
    }
}
@media all and (min-width: 1366px) {
    .story-section {
        h2 {
            font-size: 48px;
        }
    }
}
