footer {
	width: 100%;
	float: left;
	background-color: $roughwood-brown;
	position: relative;
	z-index: 2;
	padding: 1em 0;
	.content {
		max-width: 40em;
		display: flex;
		flex-direction: column; 
		> div {
			width: 100%;
		}
	}
	h3 {
		font-size: 2em;
		font-family: $font-roughwood;
		color: $roughwood-grey;
		padding-left: 5%;
		width: 100%;
		float: left;
		text-align: center;
		margin: 1em 0;
	}
	h4 {
		width: 100%;
		text-align: center;
		color: $roughwood-grey;
		font-family: $font-body;
		font-size: 1.1em;
		padding: 0.75em 0;
		float: left;
	}
}

.store-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	p {
		font-size: 1.9em;
		text-align: center;
		a {
			color: inherit;
			text-decoration: underline;
		}
		&:first-of-type {
			margin-bottom: 1em;
		}
		&:last-of-type {
			margin-top: 1em;
		}
	}
}
.social-container {
	width: 100%;
	float: left;
	max-width: 40em;
	padding: 2em 0;
	ul {
		width: 100%;
		float: left;
	}
	li {
		width: 20%;
		height: 3em;
		float: left;
		margin: 0 2.5%;
		position: relative;

	}
	svg {
		width: 3em;
		height: 3em;
		@extend %absolute-middle;
		z-index: 11;
		cursor: pointer;
		use {
			fill: $roughwood-grey;
		}
	}
}
.utility {
	width: 100%;
	float: left;
    padding: 2em 0;
    max-width: 40em;
    li {
        width: 33.333333%;
        float: left;
        text-align: center;
        color: $roughwood-grey;
        font-family: $font-body;
        font-size: 1.5em;
    }
}
