.form-container.addresses {
	margin-top: 2em;
	margin-bottom: 2em;
}
.existing-address {
	padding: 2em 0;
	position: relative;
	display: flex;
	> div {

	}
	p {
		font-size: 1.8em;
		margin-left: 0em;
		margin-top: 0.5em;
	}
	input[type="radio"] {
		margin: 1em;
		float: left;
	}
	.newAddress {
		font-size: 1.8em;
		display: inline;
		width: auto;
		line-height: 2.5em;
		padding: 0;
		margin: 0;

	}
    button {
        background-color: transparent;
        color: $roughwood-brown;
        line-height: 2em;
        cursor: pointer;
        font-family: $font-body;
        border-bottom: 1px solid $roughwood-brown;
        font-size: 1.4em;
        text-align: center;
        @extend %default-transition;
        @include position(absolute, auto 1em auto auto);
        @include transform(translateY(-50%));
        &.edit {
        	top: 30%;
        	right: 1.6em;
        }
        &.delete {
        	top: 60%;
        }
    }
}
