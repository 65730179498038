// // // // // // // // // // //
//
//  Login modal
//
// // // // // // // // // // //

.login-modal {
  @extend %center;
  @include transitions(all 0.6s ease-in-out);
  background-color: rgba($roughwood-brown, 0.5);
  color: white;
  position: absolute;
  top: 80px;
  width: 95%;
  max-width: 800px;
  height: 420px;
  padding: 20px 5px;
  border-radius: 8px;
  z-index: 10000;
  overflow: hidden;
  border: 1px solid rgba($roughwood-grey-dark, 0.2);
  @include mediaMin($modal-collapse) {
    top: 25%;
  }

  div.content {
    max-width: 450px;
  }

  label {
    font-size: 1.230769231em;
  }

  .login-only-l,
  .login-only-r,
  .l-name,
  .f-name,
  .email-input,
  .password-input,
  .confirm-password-input,
  .signup-btn,
  .zipcode {
    @include transitions(all 0.6s ease-in-out);
    @include mediaMax($modal-collapse - 1) {
      width: 300px;
    }
    @include mediaMin($modal-collapse) {
      &:not(.checkbox-label),
      &:not(.clickable.login-only-l) {
        width: 280px;
        height: 50px;
      }
    }
  }
  .accept {
    width: 200px;
  }
  .back-to-login-btn {
    @include transitions(all 0.6s ease-in-out);
    @include transform(translateX(-50%));
    background-color: transparent;
    color: $roughwood-grey-dark;
    position: absolute;
    bottom: 40px;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    @include mediaMin($modal-collapse) {
      @include transform(none);
      bottom: 50px;
      left: 0;
    }
  }
  // - - - - - - - - - - - - - -
  // Signup Animation STYLES
  // - - - - - - - - - - - - - -
  &.signup {
    height: 550px;
    @include mediaMin($modal-collapse) {
      height: 420px;
    }
    .back-to-login-btn {
      opacity: 1;
      pointer-events: all;
    }
    .login-only-l {
      @include transform(translateX(-50vw));
      opacity: 0;
      @include mediaMax($modal-collapse - 1) {
        @include transform(translateX(-130vw));
      }
    }
    .login-only-r {
      @include transform(translateX(50vw));
      opacity: 0;
      @include mediaMax($modal-collapse - 1) {
        @include transform(translateX(130vw));
      }
    }
    .signup-btn {
      @include transform(translateY(100px));
      &:after {
        content: 'Sign Up';
      }
      @include mediaMin($modal-collapse) {
        @include transform(translate(-55%, 100px));
        width: 300px;
      }
    }
    .email-input,
    .password-input {
      @include transform(translateY(100px));
      @include mediaMin($modal-collapse) {
        @include transform(translateY(45px));
        width: 280px;
      }
    }
    .signup-name {
      @include transform(translateX(0));
      opacity: 1;
    }
    .signup-bottom {
      @include transform(translate(0, 200px));
      opacity: 1;
    }
  } // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  &.error {
    @include mediaMax($modal-collapse - 1) {
      height: 500px;
    }
  }
  &.multi-error {
    @include mediaMax($modal-collapse - 1) {
      height: 600px;
    }
  }

  //
  // Signup Form Fields
  //
  .signup-name {
    @include transform(translateX(130vw));
  }
  .signup-bottom {
    @include transform(translate(130vw, 200px));
  }
  .signup-name,
  .signup-bottom {
    @include transitions(all 0.6s ease-in-out);
    position: absolute;
    width: calc(100vw - 75px);
    opacity: 0;
    @include mediaMin($modal-collapse) {
      width: 640px;
      .f-name,
      .l-name {
        display: inline-block;
        width: 280px;
        margin: 0 5px;
      }
      .f-name {
        float: left;
      }
      .l-name {
        float: right;
      }
      .zipcode {
        @include transform(translate(355px, -202px));
        width: 280px;
      }
      .confirm-password-input {
        @include transform(translate(5px, -2px));
        display: block;
        width: 280px;
      }
      .accept {
        @include transform(translate(355px, -180px));
        width: 270px;
      }
    }
    h3,
    input {
      font-size: 18px;
      line-height: 36px;
    }
  }

  // - - - - - - - - -
  // Login Form
  // - - - - - - - - -
  .login {
    position: relative;
    height: inherit;
    @include mediaMax($modal-collapse - 1) {
      max-width: 300px;
    }
    @include mediaMin($modal-collapse) {
      width: 650px;
      height: 390px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .not-member {
        display: none;
      }
    }
    &.fade {
      display: none;
      pointer-events: none;
    }
    .column {
      max-width: 300px;
      margin: 0 auto 15px;
      @include mediaMin($modal-collapse) {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 300px;
        margin: 0 5px;
        &.login-column {
          justify-content: space-around;
        }
        &.signup-column {
          text-align: center;
          h2 {
            margin-bottom: 5px;
          }
        }
        p.member-message {
          height: auto;
        }
        input,
        label {
          margin: 0;
          &[type='checkbox'] {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
