// // // // // // // // // // //
//
// Login/Signup labels
//
// // // // // // // // // // //

.signin-form {
  label {
    display: inline-block;
    margin-bottom: 15px;
    &.accept {
      font-size: 14px;
      margin: 10px 0;
      input {
        margin: 20px 0;
        margin-right: 20px;
      }
    }
    a,
    span {
      color: $roughwood-grey;
      text-decoration: none;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid $light;
    }
    &.checkbox-label {
      max-width: 140px;
      cursor: pointer;
    }
    &.clickable {
      display: inline-block;
      font-size: 1em;
      &.not-member {
        margin-top: 15px;
      }
      span {
        margin-left: 8px;
      }
    }
  }
}
