.browse {
    width: 100%;
    float: left;
}

.selection {
    float: left;
    width: 100%;
    padding-bottom: 150%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        @include position(absolute, 0px auto auto 0px);
        background: rgba($roughwood-brown, 0.3);
        z-index: 9;
        opacity:0.5;
        @extend %default-transition;
    }
    button {
        background: transparent;
        color: $roughwood-grey;
        border: 2px solid $roughwood-grey;
        padding: 10px 20px;
        font-size: 24px;
        font-family: $font-roughwood;
        @include position(absolute, 50% auto auto 50%);
        @include transform(translateX(-50%));
        @extend %default-transition;
        z-index: 10;
        min-width: 200px;
    }
    p {
        color: $light;
        font-size: 2.2em;
        @include position(absolute, 65% auto auto 50%);
        @include transform(translateX(-50%));
        @extend %default-transition;
        width: 80%;
        text-align: center;
    }
}
.no-touchevents {
    .selection:hover {
        &:after {
            opacity: 1;
        }
        button {
            background: $roughwood-grey;
            color: $roughwood-brown;
        }
    }
}
@media all and (min-width: 600px) {
    .browse {
        .scroll-down {
            display: none;
        }
    }
    .selection {
        width: 50%;
        height: 100vh;
        padding-bottom: 0;
    }
}
