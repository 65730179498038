table {
    border: 1px solid #ccc;
    width: 100%;
    margin:1em 0;
    float: left;
    padding:0;
    border-collapse: collapse;
    border-spacing: 0;
    color: $light;
    font-family: $font-body;
    font-size: 1.4em;
    tr {
        border: 1px solid #ddd;
        padding: 5px;
    }
    th, td {
    padding: 10px;
    text-align: center;
    }
    th {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    }
}
// SIZING MODAL

.size-container {
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 50px);
    background-color: $roughwood-grey-dark;
    @include position(fixed, 50px auto auto 0px);
    position: fixed;
    display: none;
    z-index: 100;
    padding: 2em;
    h2 {
        width: 100%;
        text-align: left;
        font-size: 2em;
        color: $roughwood-brown;
        font-family: $font-roughwood;
    }
}
.close-sizing {
    @include appearance(none);
    @include position(absolute, 10px 10px auto auto);
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    @extend %default-transition;
    &:after {
        content: "";
        height: 26px;
        width: 2px;
        background: $roughwood-brown;
        @include position(absolute, 50% auto auto 50%);
        @include transform(translateY(-50%) translateX(-50%) rotate(45deg));
        @extend %default-transition;
    }
    &:before {
        content: "";
        height: 26px;
        width: 2px;
        background: $roughwood-brown;
        @include position(absolute, 50% auto auto 50%);
        @include transform(translateY(-50%) translateX(-50%) rotate(-45deg));
        @extend %default-transition;
    }
}
.show-size {
    display: block;
}
@media screen and (max-width: 600px) {
    table {
        border: 0;
        thead {
          display: none;
        }
        tr {
            margin-bottom: 10px;
            display: block;
            border-bottom: 2px solid #ddd;
        }
        .row-label {
            text-align: center;
            font-size: 1.4em;
        }
        td {
            display: block;
            text-align: right;
            font-size: 13px;
            border-bottom: 1px dotted #ccc;
        }
        td:last-child {
            border-bottom: 0;
        }
        td:before {
            content: attr(data-label);
            float: left;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}

