section.block-headline {
  width: 100%;
  float: left;
  padding: 1.5em 0;
  h2 {
    width: 100%;
    text-align: center;
    font-size: 2.4em;
    margin: 0;
  }

  svg {
    fill: $roughwood-brown;
    width: 80%;
    max-width: 400px;
    height: 60px;
    margin: 0 auto;
    display: block;
  }
}

@media all and (min-width: 50em) {
  section.block-headline {
    h2 {
      font-size: 3.6em;
    }
  }
}

@media all and (min-width: 64em) {
  section.block-headline {
    h2 {
      font-size: 4.8em;
    }
    &.extraSmall h2 {
      font-size: 4em;
    }
    &.small h2 {
      font-size: 4.4em;
    }
    &.medium h2 {
      font-size: 4.8em;
    }
    &.large h2 {
      font-size: 5.2em;
    }
    &.extraLarge h2 {
      font-size: 5.6em;
    }
  }
}
