section.block-full-gallery {
  width: 100%;
  float: left;
  z-index: 2;
  height: 100vh;
  overflow: hidden;
  float: left;
  margin-bottom: 0px;
  .slick-slide {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    zoom: 1;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($dark, 0.25);
      z-index: 9;
    }
  }
  div.cta-container {
    &.extraSmall p {
      font-size: 1.6em;
    }
    &.small p {
      font-size: 1.75em;
    }
    &.medium p {
      font-size: 1.9em;
    }
    &.large p {
      font-size: 2.05em;
    }
    &.extraLarge p {
      font-size: 2.2em;
    }
  }
}
