.mobile-menu {
    width: 100%;
    height: calc(100vh - 50px);
    @include position(fixed, 60px -100% auto auto);
    background: $roughwood-brown-nav;
    padding: 0;
    margin:0;
    z-index: 200;
    @extend %default-transition;
    overflow: auto;
    padding-bottom: 5em;
    ul {
        width: 100%;
        float: left;
        li {
            @extend %default-transition;
            cursor: pointer;
        }
    }
    a {
        text-decoration: none;
        color: inherit;
    }
}
.menu-open {
    right: 0px;
}
.touchevents {
    .secondary {
        li {
            svg {
                display: none;
            }
            span {
                opacity: 1;
                color: $roughwood-grey;
            }
        }
    }
}
.no-touchevents {
    .primary {
        li:hover {
            background: $roughwood-grey;
            color: $roughwood-brown;
        }
    }
    .secondary {
        li:hover {
            background: $roughwood-grey;
            svg {
                opacity: 0;
                use {
                    fill: $roughwood-brown;
                }
            }
            span {
                opacity: 1;
            }
        }
        .logout:hover,
        .sign-in:hover {
            color: $roughwood-brown;
        }
    }
}
@media all and (min-width: 800px) {
    .primary,
    .secondary {
        li {
            font-size: 2em;
        }
    }
}
@media all and (min-width: 64em) {
    .primary {
        display: none;
    }
    .mobile-menu {
        padding-bottom: 1.5%;
    }
}