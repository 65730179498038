.info {
    width: 100%;
    float: left;
    @include clearfix();
    background-color: $roughwood-brown;
    position: relative;
    h2 {
        width: 100%;
        font-size: 28px;
        font-family: $font-roughwood;
        margin-top: 60px;
        color: #FFF;
        background-color: $roughwood-brown;
        text-align: center;
    }
    h3 {
        width: 100%;
        text-align: left;
        font-size: 22px;
        line-height: 24px;
        font-family: $font-body;
        color: #fff;
        padding: 0 5%;
        font-weight: 700;
        margin-top: 20px;
    }
    p {
        width: 100%;
        color: #FFF;
        padding: 0 5%;
        margin: 10px 0;
        font-size: 16px;
        line-height: 24px;
        font-family: $font-body;
        strong {
            font-size: 20px;
        }
    }
    ul {
        width: 80%;
        float: left;
        margin: 20px 10%;
        li {
            list-style-type: disc;
            color: #fff;
            font-size: 18px;
            line-height: 22px;
            font-family: $font-body;
            margin: 5px 0;
        }
    }
    a {
        color: $roughwood-grey-dark;
        position: relative;
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: $roughwood-grey-dark;
            @include position(absolute, auto auto -4px 0px);
        }
    }
}
.icon-filler-5 {
    width: 80%;
    max-width: 175px;
    margin: 0 auto;
    display: block;
    use {
        fill: $product-background;
    }
}
.info {
    section {
        width: 100%;
        float: left;
    }
}
