main.gifts {
    min-height: 100vh;
    background-color: $roughwood-grey-dark;
}

main.gifts-entry {
    width: 100%;
    float:left;
    min-height: 100vh;
    padding-top: 50px;
    section.scroll {
        top: 0px;
    }
}