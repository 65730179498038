.checkout-container {
    height: 50px;
    width: 100px;
    display: inline;
    .register {
        height: 40px;
        width: auto;
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 55px;
    }
    .mannequin {
        height: 40px;
        width: auto;
        cursor: pointer;
        position: absolute;
        top: 105px;
        right: 5px;
    }

        .item-number {
            color: $roughwood-brown;
            margin: 0;
            font-size: 16px;
            display: inline;
            position: absolute;
            right: 20px;
            top: 122.5px;
            cursor: pointer;
        }

    .account-info {
        background: $roughwood-brown-nav;
        width: 175px;
        position: fixed;
        top: 50px;
        color: #FFF;
        opacity: 1;
        transition-duration: 0.5s;
        font-size: 12px;
        right: -175px;
        z-index: 4;


        li {
            list-style-type: none;
            text-align: left;
            height: 50px;
            vertical-align: middle;
            cursor: pointer;
            padding-top: 19px;
            padding-left: 5px;
        }
        li:hover {
            background: $roughwood-menu-hover;
        }
        a {
            text-decoration: none;
            color: inherit;
        }
    }
    .account-info-open {
        opacity: 1;
        right: 0;
    }
}


