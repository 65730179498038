section.block-four-image {
  width: 100%;
  float: left;
  figure {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    padding-bottom: 100%;
    background-size: cover;
    position: relative;
    &:after {
      content: '';
      display: block;
      @include position(absolute, 0px 0px 0px 0px);
      background-color: rgba($dark, 0.25);
      z-index: 1;
      opacity: 0;
      @extend %default-transition;
    }
  }
  a.cta {
    @extend %absolute-middle;
    z-index: 2;
    @extend %default-transition;
    opacity: 0;
  }
}

.no-touchevents {
  section.block-four-image {
    figure:hover {
      &:after {
        opacity: 1;
      }
      a.cta {
        opacity: 1;
      }
    }
  }
}

.touchevents {
  section.block-four-image {
    figure:after {
      opacity: 1;
    }
    a.cta {
      opacity: 1;
    }
  }
}

@media all and (min-width: 37.5em) {
  section.block-four-image {
    figure {
      width: 50%;
      padding-bottom: 50%;
    }
  }
}

@media all and (min-width: 56.25em) {
  section.block-four-image {
    figure {
      width: 25%;
      padding-bottom: 25%;
    }
  }
}
