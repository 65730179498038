// // // // // // // // // // //
//
// Signin and Singup modal
//
// // // // // // // // // // //

.login-page {
  @extend %default-transition;
  @extend %cover;
  @include transform(translateY(-400%));
  font-family: $font-body;
  opacity: 0;
  z-index: 99999;
  &.show {
    @include transform(translateY(0%));
    @include transitions(
      all 0.3s ease-in-out 0.1s,
      opacity 0.6s ease-in-out 0.1s
    );
    opacity: 1;
    .login-modal {
      transition-delay: 0.2s; // autoprefixer will take care of vendor prefixes
    }
  }
}

.bg-overlay {
  @extend %default-transition;
  @extend %cover;
  content: '';
  background-color: rgba(black, 0.7);
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  &.show {
    z-index: 9999;
    opacity: 1;
  }
}

.signup-error {
  position: absolute;
  bottom: 120px;
  color: red;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  @include mediaMax($modal-collapse - 1) {
    position: relative;
    top: 80px;
  }
}

.signup-btn {
  @include mediaMax($modal-collapse - 1) {
    display: block;
    width: 60%;
    margin: 0 auto;
  }
  &:after {
    content: 'Learn More';
    @include mediaMin($modal-collapse) {
      content: 'Sign Up';
    }
  }
}

.signin-form {
  position: relative;
  display: block;
  font-size: 1.4em;
  width: 100%;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 30px;
  z-index: 10;

  h2 {
    color: $roughwood-grey-dark;
    font-size: 20px;
    width: 100%;
    text-align: center;
    &.not-a-member-h2 {
      font-size: 2em;
    }
  }
  // TEXT
  p {
    width: 100%;
    padding: 0.5em 0;
    text-align: center;
    &.member-message {
      font-size: 1.2em;
      line-height: 1.5em;
      margin-bottom: 38px;
      @include mediaMax($modal-collapse - 1) {
        display: none;
      }
    }
    &.error-msg {
      // position: absolute;

      font-size: 1.2em;
      color: $invalid;
      background-color: white;
      width: 100%;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
  .btn,
  input {
    background-color: transparent;
    color: $roughwood-grey-dark;
    font-size: 18px;
    line-height: 36px;
    &.signup-btn {
      margin-top: 20px;
    }
  }
  .btn {
    border-radius: 2px;
    border: 1px solid rgba($roughwood-grey-dark, 0.7);
    &:hover {
      background-color: rgba($roughwood-grey-dark, 0.1);
      border-color: rgba($roughwood-grey-dark, 0.4);
    }
  }
  input {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba($roughwood-grey-dark, 0.2);
    @include placeholder {
      color: $roughwood-grey;
      font-style: italic;
      font-size: 16px;
    }
  }
}
.hide {
  display: none;
}
