div.price-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .price {
    width: 100%;
  }
}
.discount-mark {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  background-color: $roughwood-brown;
  position: relative;

  > span {
    font-size: 1.5em;
    width: 2.5em;
    font-family: $font-body;
    text-align: center;
    color: $roughwood-grey
  }
}

.product-card {
  display: flex;
  padding: 1em 0;
  transition: all 0.3s ease-in-out;
  position: relative;
  .no-touchevents & {
    &:hover {
      -webkit-box-shadow: 0px 0px 10px 10px rgba(0,0,0, 0.15); 
      box-shadow: 0px 0px 10px 10px rgba(0,0,0, 0.15);
    }
  }
  border-radius: 10px;
  a.link {
    font-family: $font-body;
    font-size: 1.6em;
    color: inherit;
    border-bottom: 1px solid $roughwood-brown;
    align-self: center;
  }
  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  span.designer {
    font-family: $font-body;
    font-size: 1.5em;
    color: $product-background;
  }
  p.name {
    font-family: $font-roughwood;
    font-size: 3em;
    margin-top: .5em;
    color: $roughwood-brown;
  }
  p.price {
    font-size: 2.4em;
    color: $roughwood-brown;
  }
  button.cta {
    padding: 0.25em .5em;
    color: $roughwood-brown;
    border-color: $roughwood-brown;
    font-weight: 400;
    font-family: $font-body;
    margin: 1em 0;
    &:hover {
      background-color: $roughwood-brown;
      color: $roughwood-grey;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  @media all and (min-width: 600px) {
    flex-direction: column;
    border: 1px solid $roughwood-brown;
    padding: 2em 0;
    margin-bottom: 1em;
    div.price-container {
      .price {
        width: auto;
      }
      > div {
        margin-left: 1em;
      }
    }
    div.discount-mark {
      top: -55%;
    }
    a.image-link {
      width: 80%;
    }
    > div {
      width: 100%;
    }
  }
  &.dressing-card {
    flex-direction: column;
    align-content: center;
    @media all and (max-width: 600px) {
      img {
        width: 50%;
        display: block;
        margin: 0 auto;
      }
    }
    a.link {
      color: white;
      border-color: white;
      text-align: center;
      margin-top: 10px;
    }
    > div {
      width: 100%;
    }
  }
}
