section.block-line-break {
  width: 100%;
  float: left;
  svg {
    fill: $roughwood-brown;
    width: 80%;
    max-width: 400px;
    height: 60px;
    margin: 0 auto;
    display: block;
  }
}

@media all and (min-width: 50em) {
  section.block-line-break {
    padding: 2em 0;
  }
}
