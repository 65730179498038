// // // // // // // // // // //
//
// Filter Button
//
// // // // // // // // // // //

div.filter-bar {
  @extend %default-transition;
  @include position(fixed, 60px 0px auto auto);
  background-color: rgba($roughwood-grey-dark, 0.7);
  width: 100px;
  width: 100%;
  height: 50px;
  // text-align: center;
  z-index: 20;
  button.cta {
    position: relative;
    background-color: transparent;
    color: $roughwood-brown;
    font-family: $font-body;
    height: 40px;
    line-height: 40px;
    padding: 0 1em;
    border-color: $roughwood-brown;
    // border: none;
    margin-top: 5px;
    margin-left: 5px;
    z-index: 1;
    &:hover {
      background-color: transparent;
      color: lighten($roughwood-brown, 15%);
    }
  }
  //
  // Current filters inside the FILTER bar underneath nav
  //
  .current-filters-list {
    @include transform(translateY(-50%));
    font-family: $font-body;
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    width: 65%;
    height: 18px;
    z-index: 99;
    overflow: hidden;
    @include mediaMin(700px) {
      width: 85%;
    }
    .filter-box {
      margin: 0 2px;
      height: 18px;
      line-height: 18px;
      vertical-align: top;
      cursor: pointer;
      font-size: 1.4em;
      border: none;
      &.clear-filters {
        background-color: transparent;
        border: 1px solid $roughwood-grey;
        width: auto;
        padding-left: 20px;
        padding-right: 10px;
      }
      a {
        display: inline-block;
        width: 100%;
        pointer-events: none;
      }
    }
  }
}
