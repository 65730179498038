.checkout,
.profile {
    width: 100%;
    float: left;
    @include clearfix();
    position: relative;
    padding-bottom: 100px;
    &:after {
        content: "";
        background-color: $roughwood-grey;
        height: 100vh;
        width: 100%;
        @include position(fixed, 0px auto auto 0px);
        z-index: -2;
    }
    .info-form {
        position: relative;
        max-width: none;
    }
    h1 {
        width: 100%;
        text-align: center;
        font-size: 30px;
        font-family: $font-roughwood;
        float: left;
        margin: 1em 0;
    }
}
.form-container {
    width: 100%;
    float: left;
    background: rgba($roughwood-brown, 0.15);
    padding: 2em 0;
    h2 {
        font-size: 2.4em;
        display: inline-block;
        color: $roughwood-brown;
        font-family: $font-body;
        padding-left: 0.5em;
        margin: 0.75em 0;
        width: 100%;
        font-weight: 700;
    }
    div {
        width: 100%;
        float: left;
        color: $roughwood-brown;
        font-family: $font-body;
        label {
            width: 100%;
            float: left;
            $font-family: $font-body;
        }
        input {
            width: 100%;
            float: left;
        }
    }
    .select {
        position: relative;
        width: 90%;
        margin: 10px 5%;
        &:after {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 15px solid $roughwood-brown;
            @include position(absolute, auto 2.5% 10px auto);
        }
        select {
            width: 100%;
            @include appearance(none);
            border-radius: 0;
            height: 2.5em;
            background-color: transparent;
            color: $roughwood-brown;
            font-size: 16px;
            padding: 0 0.5em;
        }
    }
    .select {
        label {
            padding-left: 0;
            margin: 10px 0;
        }
    }
    .final-products {
        span {
            font-size: 16px;
            padding: 0 5%;
            margin: 2.5px 0;
            width: 100%;
            float: left;
        }
    }
    label {
        font-size: 16px;
        padding: 0 5%;
        margin: 5px 0;
    }
    input[type="checkbox"],
    input[type="radio"] {
        @include appearance(none);
        height: 20px;
        width: 20px;
        background-color: $roughwood-grey;
        @include transition(0.05s);
        @include transform(translate3d(0,0,0));
        margin: 10px 5%;

    }
    input[type="checkbox"] {
        position: relative;
        background-color: $roughwood-grey;
        &:after,
        &:before {
            content: "";
            background-color: $roughwood-brown;
            width: 5px;
            height: 20px;
            @include transition(0.15s);
            @include position(absolute, -3px auto auto 13px);
            @include transform(rotate(45deg));
            opacity: 0;
        }
        &:after {
            height: 10px;
            @include position(absolute, 5px auto auto 5px);
            @include transform(rotate(-45deg));
        }
    }
    input[type="checkbox"]:checked {
        &:after,
        &:before {
            opacity: 1;
        }
    }
    input[type="radio"] {
        border-radius:50%;
    }
    input[type="radio"]:checked {
        background-color: $roughwood-grey;
        border: 7px solid $roughwood-brown;
    }

    input[type="text"],
    input[type="number"],
    input[type="name"],
    input[type="email"] {
        width: 90%;
        border: none;
        margin: 10px 5%;
        padding: 0 0.5em;
        background-color: transparent;
        border: 1px solid $roughwood-brown;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
        font-family: $font-body;
        color: $roughwood-brown;
        @include placeholder {
            font-family: $font-body;
            font-size: 16px;
            font-style: italic;
            color: rgba($roughwood-brown, 0.4);
        }
    }
    textarea {
        width: 90%;
        margin: 0 5%;
        margin-top: 10px;
        background-color: transparent;
        border: 1px solid $roughwood-brown;
        height: 150px;
        color: $roughwood-brown;
        font-size: 14px;
        @include placeholder {
            font-style: italic;
            color: rgba($roughwood-brown, 0.4);
            font-size: 14px;
        }
    }
    .same-address {
        label {
            width: auto;
            margin: 10px 0;
            padding: 0 1.5%;
            line-height: 20px;
        }
        input {
            float: left;
            margin: 10px 1.5%;
            margin-left: 1em;
        }
    }
}
.form-container.hidden {
    display: none;
}
.complete {
    width: 100%;
    float: left;
    margin-top: 20px;
    padding-bottom: 30px;
    button[type="submit"] {
        border: none;
        outline: none;
        font-family: $font-body;
        font-size: 18px;
        line-height: 30px;
        height: 30px;
        width: 90%;
        margin: 20px 5%;
    }
}
@media all and (min-width: 800px) {
    .checkout {
        .submit-container {
            button[type="submit"] {
                width: 300px;
                display: block;
                margin: 0 auto;
            }
        }
        .form-container {
            width: 50%;
            margin: 10px 25%;
            padding: 3em 0%;
        }
    }
}


