
.updated-profile {
    background:rgba(27, 23, 23, 0.15);
    margin-bottom: 40px;
 }
.profile {

    .new-profile {
        width: 100%;
        /* background: rgba(27, 23, 23, 0.15); */
        margin-top: 10px;
        padding-top: 24px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 10px auto 42px;
        .row {
            position: relative;
            max-width: 1250px;
            margin: 0px auto;
            width: 100%;
            float: left;
            margin: 1em 0;
         }
         h2 {
            font-size: 44px;
            color: black;
            float: left;
         }
         .button {
            background-color: black;
            color: white;
            padding: 16px 20px;
            font-size: 18px;
            font-family: "Fondamento";
            font-weight: 900;
            margin-top: 1em;
            float: left;
            &.add {
                position: absolute;
                top: 0;
                right: 0;
            }
            &.button-inline {
                margin: 1em 30px;
            }
            &.edit {
                float: right;
            }
         }
         .row:after {
            clear: both;
         }
         .profile-pic {
            background-color:rgba(27, 23, 23, 0.9);
            height: 300px;
            width: 300px;
            float: left;
        }
        .profile-data {
            float: left;
            margin-left: 10px;
            h3 {
                font-size: 40px;
                color: black;
                margin-bottom: 0px;
                margin-top: 40px;
             }
             p {
                padding: 0;
                color: black;
                font-size: 22px;
             }
        }
        h4 {
            font-family: 'Alegreya', serif;
            font-size: 2.4em;
            font-weight: 700;
         }
         .black-background {
            background: #1c1717;
            padding: 16px 0px;
            width: 100%;
            float: left;
            p {
                color: white;
                font-size: 24px;
                font-weight: 900;
                span {
                    font-weight: 900;
                 }
             }
         }
         .grey-background {
            background: #aaa7a3;
            padding: 16px 0px;
            width: 100%;
            float: left;
            p {
                color: #1c1717;
                font-size: 24px;
                font-weight: 500;
                span {
                    font-weight: 900;
                 }
             }
         }
         .tan-background {
            background: #e0ddd7;
            padding: 16px 0px;
            padding-bottom: 0px;
            width: 100%;
            float:left;
            p {
                color: #1c1717;
                font-size: 24px;
                font-weight: 500;
                span {
                    font-weight: 900;
                 }
             }  
         }  
         .box-style {
            border-top: 1px solid #aaa7a3;
            padding-top: 30px;
            padding-bottom: 30px;
         }
         .last-box {
            margin-bottom: 60px;
         }
         .half-width {
            margin-top: 24px;
            padding: 0 30px;
         }
         .half-width:after {
            content: '';
            clear: both;
            display: block;
         }
         .half-width p {
            width: 50%;
            float: left;
            font-weight: 500;
            margin: 0;
            padding: 0;
            span {
                font-weight: 500;
                width: calc(100% - 150px);
             }
         }
         .border-bottom {
            border-top: 1px solid #aaa7a3;
            padding-top: 24px;
            padding-bottom: 24px;
         }
         .placeholder {
            display: inline !important;
            opacity: .25;
            font-weight: 100 !important;
            font-style: italic;
        }
     }
}

@media all and (max-width:676px){
    .profile {
        .new-profile {
            h2 {
                float: none;
                width: 100%;
            }
            .edit-profile {
                float: none;
                position: static;
                margin-bottom: 24px;
                display: inline-block;
                margin-top: 0;
            }
            .add-address label,.edit-address label {
                width: 100% !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
            .add-address .submit, .edit-address .submit {
                width: 100% !important;
                margin-left: 0 !important;
                margin-bottom: 18px;
            }
            .profile-header {

                .profile-pic {
                    width: 100%;
                    height: 300px;
                    float: none;
                }
                .profile-data {
                    float: none;
                    margin-bottom: 18px;
                    margin-left: 16px;
                    h3 {
                        margin-top: 12px;
                    }
                    strong {
                        font-weight: 700;
                    }
                }
            }
            .half-width p {
                float: none;
                width: 100%;
            }
        }
    }
}

@media all and (max-width: 430px) {
    .profile .new-profile .edit-profile {
        float: none;
        margin-bottom: 0;
    }

}

@media all and (max-width: 640px) {
    .profile .new-profile .profile-header{
        padding-bottom: 1px;
    }
    .profile .new-profile .profile-data {
        margin-left: 15px;
    }
}
