ul.desktop-nav {
  display: flex;
  flex-wrap: wrap;
  background-color: $roughwood-grey-dark;
  > li {
    position: relative;
    width: 100%;
    height: 32.5vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 20px solid $roughwood-grey-dark;
    &:after {
      content:"";
      background-color: black;
      opacity: 0.25;
      @include position(absolute, 0px 0px 0px 0px);
    }
  }
  span {
    position: relative;
    color: white;
    font-size: 3.2em;
    z-index: 2;
    &:after {
      @include position(absolute, auto auto 2px 50%);
      @include transform(translateX(-50%));
      @extend %default-transition;
      content: '';
      background-color: white;
      display: block;
      width: 0%;
      height: 3px;
      z-index: 2;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}

.no-touchevents {

  .desktop-nav p:hover {
    span:after {
      width: 100%;
    }
  }
}

.desktop-nav > li:nth-child(2) ul.sub-nav > li {
  width: 25%;
}

@media all and (min-width: 64em) {
  ul.desktop-nav {
    > li {
      width: 50%;
      height: 47.5vh;
      &:nth-child(1),
      &:nth-child(2) {
          border-top-width: 30px;
      }
      &:nth-child(3),
      &:nth-child(4) {
          border-bottom-width: 30px;
      }
      &:nth-child(odd) {
        border-left-width: 40px;
      }
      &:nth-child(even) {
          border-right-width: 40px;
      }
    }
    span {
      font-size: 4em;
    }
  }
}
