// // // // // // // // // // //
//
//  Login checkbox
//
// // // // // // // // // // //

.signin-form {
  input {
    // CHECKBOX
    &[type='checkbox'] {
      @include appearance(none);
      @include transition(0.15s);
      position: relative;
      height: 20px;
      width: 20px;
      margin: 0;
      margin-right: 15px;
      padding: 0;
      float: left;
      cursor: pointer;
      border: 1px solid $product-background;
      &:after,
      &:before {
        @include transition(0.15s);
        @include position(absolute, -3px auto auto 13px);
        @include transform(rotate(45deg));
        content: '';
        background-color: $roughwood-grey;
        width: 5px;
        height: 20px;
        opacity: 0;
      }
      &:after {
        @include position(absolute, 5px auto auto 5px);
        @include transform(rotate(-45deg));
        height: 10px;
      }
      &:checked {
        &:after,
        &:before {
          opacity: 1;
        }
      }
    }
  }
}
