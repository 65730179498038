aside.collections-menu {
  @include position(fixed, 60px auto auto 0px);
  @include transform(translateX(300%));
  @include transition(0.5s);
  background-color: $roughwood-grey-dark;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 999;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &.reveal {
    @include transform(translateX(0%));
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li {
      width: 100%;
      height: 30vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 40px solid white;
      position: relative;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        @include position(absolute, 0px 0px 0px 0px);
        background-color: black;
        opacity: 0.25;
      }
    }
  }
  a {
    text-align: center;
    font-size: 3em;
    color: white;
    position: relative;
    z-index: 3;
    span:after {
      background-color: white;
    }
  }
  @include mediaMin(50em) {
    ul {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 50%;
        height: 47.5vh;
        &:nth-child(1),
        &:nth-child(2) {
            border-top-width: 60px;
        }
        &:nth-child(3),
        &:nth-child(4) {
            border-bottom-width: 60px;
        }
        &:nth-child(odd) {
          border-left-width: 80px;
        }
        &:nth-child(even) {
            border-right-width: 80px;
        }
      }
    }
    a {
      font-size: 4.8em;
    }
  }
}
