ul.user-nav {
  width: 100%;
  background-color: $roughwood-brown-nav;
  @extend %default-transition;
  p {
    font-size: 1.6em;
    letter-spacing: 2px;
  }

  > li {
    width: 100%;
    float: left;
    text-align: center;
    padding: 1.5em 0;
    border-bottom: 1px solid rgba($roughwood-grey, 0.15);
    position: relative;
    a {
      color: inherit;
    }
  }
  > li:last-child {
    border-bottom: none;
  }
  p.nav-item {
    position: relative;
    &:after {
      content: '';
      width: 1em;
      height: 1em;
      border-bottom: 1px solid $roughwood-grey;
      border-right: 1px solid $roughwood-grey;
      @include position(absolute, -5px 10px auto auto);
      @include transform(rotate(45deg));
    }
    &.clothing:after {
      display: none;
    }
  }
  p.scripty {
    font-size: 2.2em;
    font-family: $font-roughwood;
  }
}


ul.user-nav {
  a.cta {
    font-size: 1em;
    display: block;
    max-width: 10em;
    margin: 0 auto;
    margin-top: 1em;
  }
  > li {
    @extend %default-transition;
  }
}

.no-touchevents {
  ul.user-nav li:hover:not(:last-child):not(:first-child) {
    background-color: $roughwood-grey;
    p {
      color: $roughwood-brown;
    }
  }
  a.cta:hover {
    p {
      color: $roughwood-brown;
    }
  }
}

@media all and (min-width: 64em) {
  ul.user-nav {
    @include position(fixed, 60px 0px auto auto);
    max-width: 45em;
    @include transform(translateX(100%));
    &.open {
      @include transform(translateX(0%));
    }
  }
}
