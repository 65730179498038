.cta-container {
  width: 100%;
  @extend %absolute-middle;
  top: 55%;
  // max-width: 45em;

  h2,
  p {
    text-align: center;
    width: 100%;
  }
  h2 {
    font-size: 2.4em;
  }
  p {
    font-size: 1.6em;
    margin: 1em 0;
    padding: 0 15%;
  }
  a.cta {
    width: auto;
    margin-top: 1.25em;
    float: left;
    @extend %center;
  }
}

@media all and (min-width: 46.875em) {
  .cta-container {
    h2 {
      font-size: 3.6em;
    }
  }
}

@media all and (min-width: 64em) {
  .cta-container {
    width: auto;
    h2 {
      font-size: 4.8em;
    }
    &.bottomLeft {
      @include position(absolute, auto auto 15% 2.5%);
      @include transform(none);
    }
    &.bottomCenter {
      @include position(absolute, auto auto 15% 50%);
      @include transform(translateX(-50%));
    }
    &.topLeft {
      @include position(absolute, 17.5% auto auto 2.5%);
      @include transform(none);
    }
    &.topRight {
      @include position(absolute, 17.5% 2.5% auto auto);
      @include transform(none);
    }
    &.topCenter {
      @include position(absolute, 10% auto auto 50%);
      @include transform(translateX(-50%));
    }
    &.bottomRight {
      @include position(absolute, auto 2.5% 15% auto);
      @include transform(none);
    }
  }
}
