@include keyframes(fade-in){
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@include keyframes(burger-open){
    0% {
        background: rgba($roughwood-grey, 1);
    }
    50% {
        background: rgba($roughwood-grey, 1);
    }
    51% {
        background: rgba($roughwood-grey, 0);
    }
    100% {
        background: rgba($roughwood-grey, 0);
    }
}

@include keyframes(burger-top-open){
    0% {
        top: -10px;
        @include transform(none);
        background-color:$roughwood-grey;
    }
    50% {
        top: 0;
        @include transform(none);
        background-color:$roughwood-grey;
    }
    90% {
        top: 0;
        @include transform(rotate3d(0, 0, 1, 55deg));
        background-color: $roughwood-grey;
    }
    100% {
        top: 0;
        @include transform(rotate3d(0, 0, 1, 45deg));
        background-color: $roughwood-grey;
    }
}

@include keyframes(burger-bot-open){
    0% {
        bottom: -10px;
        @include transform(none);
        background-color: $roughwood-grey;
    }
    50% {
        bottom: 0;
        @include transform(none);
        background-color: $roughwood-grey;
    }
    90% {
        bottom: 0;
        @include transform(rotate3d(0, 0, 1, -55deg));
        background-color: $roughwood-grey;
    }
    100% {
        bottom: 0;
        @include transform(rotate3d(0, 0, 1, -45deg));
        background-color: $roughwood-grey;
    }
}

@include keyframes(burger-closed){
    0% {
        background: rgba($roughwood-grey, 0);
    }
    50% {
        background: rgba($roughwood-grey, 0);
    }
    75% {
        background: rgba($roughwood-grey, 1);
    }
    100% {
        background: rgba($roughwood-grey, 1);
    }
}

@include keyframes(burger-top-closed){
    0% {
        top: 0;
        @include transform(rotate3d(0,0,1,-45deg));
    }
    50% {
        top: 0;
        @include transform(none);
    }
    90% {
        top: -12px;
        @include transform(none);
    }
    100% {
        top: -10px;
        @include transform(none);
    }
}

@include keyframes(burger-bot-closed){
    0% {
        bottom: 0;
        @include transform(rotate3d(0,0,1,45deg));
    }
    50% {
        bottom: 0;
        @include transform(none);
    }
    90% {
        bottom: -12px;
        @include transform(none);
    }
    100% {
        bottom: -10px;
        @include transform(none);
    }
}

@include keyframes(mouse-wheel) {
    0% {
        top: 7px;
        opacity: 1;
    }
    100% {
        top: 12.5px;
        opacity: 0;
    }
}
