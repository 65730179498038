.account {
    @include clearfix();
    width: 100%;
    float: left;
    z-index: 1;
    height: 100%;
    &:after {
        @include position(fixed, 0 auto auto 0);
        content: "";
        background: $product-background;
        width: 100%;
        height: 100vh;
        z-index: 0;
    }
}
.information {
    width: 100%;
    height: 100vh;
    float: left;
    position: relative;
    z-index: 2;
}
@media all and (min-width: 1000px) {
    .information {
        width: 70%;
    }
}
