.not-found {
	width: 100%;
	float: left;
	height: 100vh;
	background-color: $product-background;
	position: relative;
    .icon-roughwood-tree {
        width: 100%;
        height: 100vh;
        @extend %absolute-middle;
        z-index: 1;
        use {
            width: 100%;
            fill: rgba($roughwood-brown, 0.15);
        }
    }
}
.not-found-container {
    width: 100%;
    padding: 0 5%;
    @extend %absolute-middle;
    z-index: 2;
    h1 {
        width: 100%;
        text-align: center;
        font-family: $font-roughwood;
        color: $roughwood-grey;
        font-size: 3em;
    }
    .confirm-button {
        background: $roughwood-grey;
        color: $roughwood-brown;
        font-size: 20px;
        padding: 15px 4%;
    }
}
