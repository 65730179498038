.profile {
    top: 40px;
    .profile-header {
        background: #aaa7a3;
        &:after{
            content: '';
            display: block;
            clear: both;
        }
    }
    .form-container {
        padding: 3em 0;
        .figure {
            display: none;
            width: 40%;
            margin: 10px auto;
        }
        .show-figure {
            display: block;
        }
        h2 {
            font-size: 1.8em;
            padding-left: 15px;
            float: left;
            span {
                color: rgba($roughwood-brown, 0.5); 
                font-size: 0.75em;
            }
        }
    }
    .edit {
        padding: 12px;
    }
    .add {
        font-size: 24px !important;
        margin: 0 8px 0px 0px !important;
    }
    .second {
        margin-top: 0;
    }
    .body-measurement {
        > div {
            width: 50%;
            float: left;
        }
        label {
            font-size: 1.5em;
            padding-left: 1em;
        }
    }
    .clothing-preference {
        input[type="text"] {
            margin: 0.5em 0.75em;
            margin-right: 0;
        }
    }
    p {
        font-family: $font-body;
        font-size: 1.6em;
        width: 100%;
        line-height: 1.5em;
        padding: 0 30px;
    }
    .confirm-button.modal-trigger {
        float: none;
    }
    .clothing-fit,
    .age,
    .interest,
    .personal {
        > div {
            width: 50%;
            float: left;
        }
        label {
            line-height: 2.5em
        }
    }
    .margin-top {
        margin-top: 25px;
    }
    .add-address {
        @include transition(0.5s);
        width: 100%;
        padding: 0px 26px;
        background: #e0ddd7;
        overflow: hidden;
        height: auto;
        max-height: 0px;
        &.active{
            max-height: 1500px;
            border-top: 1px solid #aaa7a3;
        }
        h3 {
            color: $roughwood-brown;
            font-size: 32px;
            text-align: center;
        }
        label {
            &:nth-child(odd){
                width: calc(50% - 12px);
                margin-left: 12px;
            }
            &:nth-child(even){
                width: calc(50% - 12px);
                margin-right: 12px;
            }
            background: white;
            color: #ccc;
            font-size: 18px;
            margin-bottom: 18px;
            padding: 20px;
            float: left;
            input {
                width: calc(100% - 100px);
                float: right;
            }
        }
        .submit {
            color: white;
            margin-left: 0;
        }        
    }
    .edit-address{
        @include transition(0.5s);
        background: #e0ddd7;
        width: 100%;
        height: auto;
        max-height: 0px;
        padding: 0px 26px;
        overflow: hidden;
        &.active{
            max-height: 1500px;
            border-top: 1px solid #aaa7a3;
        }
        h3 {
            color: $roughwood-brown;
            font-size: 32px;
            text-align: center;
        }
        label {
            &:nth-child(odd){
                width: calc(50% - 12px);
                margin-right: 12px;
            }
            &:nth-child(even){
                width: calc(50% - 12px);
                margin-left: 12px;
            }
            color: #ccc;
            background: white;
            font-size: 18px;
            margin-bottom: 18px;
            padding: 20px;
            float: left;
            input {
                width: calc(100% - 100px);
                float: right;
            }
        
        }
        .submit {
            color: $roughwood-grey;
            margin-left: 12px;
        }
    }
    .submit {
        background: $roughwood-brown;
        width: calc(50% - 12px);
        font-size: 18px;
        font-weight: 900;
        margin-right: 0;
        padding: 20px;
        float: none;
    }
    .select {
        position: relative;
        &:after {
            @include position(absolute, auto 2.5% 10px auto);
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 15px solid $roughwood-brown;
        }
        select {
            @include appearance(none);
            background-color: transparent;
            color: $roughwood-brown;
            font-size: 16px;
            width: 100%;
            height: 2.5em;
            padding: 0 0.5em;
            border-radius: 0;
        }
    }
}

.body-modal {
    @extend %default-transition;
    @extend %absolute-middle;
    position: fixed;
    background-color: $product-background;
    width: 100%;
    height: 0px;
    padding: 0 1em;
    opacity: 0;
    overflow: hidden;
    input[type="radio"] {
        @extend %default-transition;
        @include appearance(none);
        @include position(absolute, 0px 0px 0px 0px);
        width: 100%;
        cursor: pointer;
        border: 1px solid transparent;
    }
    input[type="radio"]:checked {
        border: 1px solid $light;
    }
    h2 {
        color: $light;
        font-family: $font-roughwood;
        font-size: 2em;
        width: 100%;
        padding: 1em 0;
        padding-top: 0.5em;
        text-align: center;
    }
    .close-modal {
        @include appearance(none);
        @include position(absolute, 0px 0px auto auto);
        background-color: transparent;
        width: 30px;
        height: 30px;
        border: none;
        outline: none;
        cursor: pointer;
        z-index: 5;
        &:before, &:after {
            @include position(absolute, 50% auto auto 50%);
            content: "";
            background-color: #FFF;
            width: 2px;
            height: 25px;
            border-radius: 5px;
        }
        &:after {
            @include transform(translateY(-50%) translateX(-50%) rotate(-45deg));
        }
        &:before {
            @include transform(translateY(-50%) translateX(-50%) rotate(45deg));
        }
    }
    img {
        @include position(absolute, 0px auto auto 50%);
        @include transform(translateX(-50%));
        height: 80%;
    }
    p {
        @include position(absolute, auto auto 2.5% 0px);
        font-size: 1.4em;
        font-family: $font-body;
        color: #FFF;
        width: 100%;
        padding: 0;
        text-align: center;
    }
}
.show-modal {
    height: 400px;
    opacity: 1;
}
.body-slick {
    position: relative;
    width: 100%;
    height: 100%;
    .slick-list,
    .slick-track {
        height: 300px;
        width: 100%;
    }
    .slick-slide {
        position: relative;
    }
    .slick-next,
    .slick-prev {
        @extend %default-transition;
        background: transparent;
        height: 40px;
        width: 80px;
        border-radius: 90px 90px 0 0;
        z-index: 100;
        &:before, &:after {
            @extend %default-transition;
            content:"";
            background: $light;
            width: 2px;
            height: 40px;
        }
        &:before {
            @include position(absolute, 50% auto auto 31%);
            @include transform(translateY(-50%) rotate(45deg));
            opacity: 1;
        }
        &:after {
            @include position(absolute, auto 31% 50% auto);
            @include transform(translateY(50%) rotate(-45deg));
        }
    }
    .slick-next {
        @include position(absolute, 50% 0px auto auto);
        @include transform(translateY(-50%) rotate(90deg));
    }
    .slick-prev {
        @include position(absolute, 50% auto auto 0px);
        @include transform(translateY(-50%) rotate(270deg));
    }
}
@media all and (min-width: 800px) {
    .profile {
        .form-container {
            width: 50%;
            margin: 0;
            min-height: 780px;
            &.second {
                min-height: 600px;
            }
        }
    }
    .body-modal {
        width: 70%;
    }
}


