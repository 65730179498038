.remove-dressing {
    background: transparent;
    color: $roughwood-grey;
    width: 40px;
    height: 40px;
    @include position(absolute, 0px 10px auto auto);
    z-index: 10000;
    &:after,
    &:before {
        content:"";
        width: 2px;
        height: 25px;
        background: $roughwood-grey;
        @extend %absolute-middle;
    }
    &:after {
        @include transform(translateX(-50%) translateY(-50%) rotate(45deg));
    }
    &:before {
        @include transform(translateX(-50%) translateY(-50%) rotate(-45deg));
    }
}
.dressing-href {
    @include transition(0.4s);
}
.scroll {
    &.dressing {
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
        }
        h2 {
            color: $roughwood-grey;
            font-size: 2em;
            font-family: $font-roughwood;
            @extend %absolute-middle;
            width: 100%;
            text-align: center;
            padding: 0 5%;
        }
        > a.cta {
            cursor: pointer;
        }
        div.cta-container {
            width: 100%;
            float: left;
            @include position(relative, auto auto auto auto);
            @include transform(translateX(0%) translateY(0%));
            max-width: none;
            margin: 1em 0;
        }
        a.cta {
            display: block;
            margin-top: 4em;
        }
        .product-card {
            margin: 4em 0;
        }
    }
}
@media all and (min-width: 600px) {
    .scroll.dressing a.cta {
        top: 61.5%;
    }
    .remove-dressing {
        top: 5px;
    }
}
.dressing-href {
    margin-bottom: 200px;
}

.stylist-section {
    display: flex;
    flex-direction: column;
}
.stylist-section p {
    text-align: center;
    margin-bottom: 5px;
    font-family: "Alegreya", serif;
    font-size: 16px;
    padding: 0px 20px;
}
.stylist-section p span {
    font-weight: 900;
    text-align: center;
}
.dressing-room-title {
    text-align: center;
    font-size: 40px;
    margin-bottom: 6px;
    margin-top: 100px;
    position:relative;
    z-index: 1;
}
.dressing-room-subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 6px;
}
.product-row {
    width: 100%;
    text-align: center;
}

.product-row.row-one p {
    font-size: 20px;
    color: white;

}
.product-row p {
    display: inline-block;
    padding: 0px 8px;
    color: white;

}

.product-row:after {
    clear: both;
    content: '';
    display: block;
}

.dressing-room-button {
    background:transparent;
    color: $light;
    border: 1px solid $light;
    padding: 14px 20px;
    transform: none;
    left: auto;
    margin: 5px auto;
    margin-top: 15px;
    float: none;
    font-weight: 700;
    @extend %default-transition;
    border-radius: 0px;
}
.no-touchevents {
    .dressing-room-button:hover {
        border-color: $roughwood-brown;
        color: $roughwood-brown;
    }
    .dressing a.cta:hover {
        color: $roughwood-brown;
        border-color: $roughwood-brown;
        background: transparent;
    }
}