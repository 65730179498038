section.block-product-gallery {
  width: 100%;
  float: left;
  background: $roughwood-grey-dark;
  padding: 5em 0;
  div.content {
    position: relative;
  }
  h2 {
    color: $roughwood-brown;
    font-family: $font-roughwood;
    font-size: 3em;
    width: 100%;
    text-align: center;
    margin: 1em 0;
  }
  .slick-slider {
    float: left;
    width: 100%;
    height: 400px;
    position: relative;
    .slick-slide {
      position: relative;
      cursor: pointer;
      height: 400px;
      img {
        height: 90%;
        width: auto;
        float: left;
        @include position(relative, auto auto auto 50%);
        @include transform(translateX(-50%));
      }
      .name {
        @include position(absolute, 5px auto auto 12.5%);
        font-size: 20px;
        color: $roughwood-brown;
        font-family: $font-body;
        opacity: 0;
        @extend %default-transition;
        z-index: 10;
      }
      .price {
        font-size: 14px;
        color: $roughwood-brown;
        font-family: $font-body;
        opacity: 0;
        @extend %default-transition;
        @include position(absolute, 30px auto auto 12.5%);
        z-index: 10;
      }
      .sale-price {
        text-decoration: line-through;
      }
    }
    .slick-next,
    .slick-prev {
      @extend %default-transition;
      height: 30px;
      width: 60px;
      border-radius: 90px 90px 0 0;
      background: transparent;
      z-index: 100;
      &:before,
      &:after {
        @extend %default-transition;
        content: '';
        width: 2px;
        height: 20px;
        opacity: 1;
        background: $roughwood-brown;
      }
      &:before {
        @include position(absolute, 50% auto auto 33%);
        @include transform(translateY(-50%) rotate(45deg));
      }
      &:after {
        @include position(absolute, auto 40% 50% auto);
        @include transform(translateY(50%) rotate(-45deg));
      }
    }
    .slick-next {
      @include position(absolute, -6px 5px auto auto);
      @include transform(translateY(-50%) rotate(90deg));
    }
    .slick-prev {
      @include position(absolute, -10px 50px auto auto);
      @include transform(translateY(-50%) rotate(270deg));
    }
    .slick-dots {
      bottom: -30px;
      display: none !important;
      button {
        &:before {
          content: '\2666 \FE0E';
          font-size: 14px;
          color: $roughwood-brown;
          opacity: 0.3;
        }
      }
      .slick-active {
        button {
          &:before {
            color: $roughwood-brown;
            opacity: 1;
          }
        }
      }
    }
  }
  p.counter {
    font-family: $font-body;
    font-size: 3em;
    @include position(absolute, -40px 52px auto auto);
  }
}
@media all and (min-width: 700px) {
  section.block-product-gallery > p {
    display: none;
  }
}

.touchevents {
  .block-product-gallery {
    .slick-slider {
      .slick-slide {
        .name,
        .price {
          opacity: 1;
        }
      }
    }
  }
}
