/*//////////////////////////////////////
//  imports
//////////////////////////////////////*/

@import 'bourbon/bourbon';
@import 'neat/neat';

/*//////////////////////////////////////
//  colors
//////////////////////////////////////*/
$dark: #000;
$light: #fff;

$product-background: #545350;
$roughwood-brown: #1b1717;
$roughwood-brown-logo: #594a42;
$roughwood-grey: #e4e2dd;
$roughwood-grey-dark: #a9a7a3;
$roughwood-brown-nav: rgba($roughwood-brown, 0.9);
$roughwood-overlay-flat: rgb(188, 188, 188);
$roughwood-menu-hover: rgba(188, 188, 188, 0.5);
$roughwood-menu-hover-hover: rgba(188, 188, 188, 0.75);
$invalid: rgba(131, 3, 0, 1);

/*//////////////////////////////////////
//  fonts
//////////////////////////////////////*/

@import 'https://fonts.googleapis.com/css?family=Alegreya:400,400i,500,700,700i';
@import url('//fonts.googleapis.com/css?family=Fondamento');
$font-sans: helvetica;
$font-serif: georgia;
// WEB FONTS
$font-body: 'Alegreya', serif;
$font-roughwood: 'Fondamento';
$font-nav: 'Fondamento';

/*//////////////////////////////////////
//  extension on skeleton
//////////////////////////////////////*/
$grid-columns: 12;
$width: 1366px;
$max-width: 1366px;
$font-size: 10;
$paragraph-size: 1.6;

// Breakpoints
$modal-collapse: 860px;

* {
  box-sizing: border-box;
}

.disable-hover {
  pointer-events: none;
}

b,
strong {
  font-weight: 700;
}

i,
em {
  font-style: italic;
}

input:focus,
textarea:focus {
  outline: none;
}

button,
input[type='submit'] {
  cursor: pointer;
}

/*//////////////////////////////////////
//  mixins
//////////////////////////////////////*/

@mixin font-size($size, $line-spacing: 1.3, $letter-spacing: 0.09) {
  font-size: ($size * $font-size) + px;
  line-height: ($size * $line-spacing) + px;

  font-size: ($size) + rem;
  line-height: ($size * $line-spacing) + rem;
  // letter-spacing:($letter-spacing) + rem;
}

@mixin input-placeholder {
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) {
    margin-right: flex-gutter();
  }
  &:nth-child(#{$nth} + 1) {
    clear: none;
  }
}

@mixin flexbox() {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -moz-box;
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
}

@mixin mediaMin($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

@mixin mediaMax($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}

@mixin mediaMinMax($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

/*//////////////////////////////////////
//  silent classes
//////////////////////////////////////*/

%block {
  max-width: $width;
  width: 100%;
  margin: 0 auto;
  display: block;
  @include clearfix();
}
a {
  text-decoration: none;
  outline: none !important;
}
button,
input {
  @include appearance(none);
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

figure {
  margin: 0;
  padding: 0;
}

%marpad {
  padding: 0;
  margin: 0;
}

%cover {
  background-size: cover;
  -ms-behavior: url(/js/vendor/backgroundsize.min.htc);
}

%default-transition {
  @include transition(all 0.4s ease-in-out);
}

@mixin transitions($trans...) {
  -webkit-transition: $trans;
  -moz-transition: $trans;
  -o-transition: $trans;
  transition: $trans;
}

@mixin center {
  @include position(relative, auto auto auto 50%);
  @include transform(translateX(-50%));
}
%center {
  @include center;
}

%cover {
  @include position(fixed, 0px 0px 0px 0px);
  width: 100%;
  height: 100vh;
}

%middle {
  @include position(relative, 50% auto auto auto);
  @include transform(translateY(-50%));
}

%absolute-middle {
  @include position(absolute, 50% auto auto 50%);
  @include transform(translateY(-50%) translateX(-50%));
}
%abs-middle {
  @include position(absolute, 50% auto auto 50%);
  @include transform(translateY(-50%) translateX(-50%));
}

%hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

body,
html {
  display: block;
  height: 100%;
}

body {
  font-size: 62.5%;
  position: relative;
  overflow-x: hidden;
  &.lock {
    overflow: hidden;
  }
}
// @media all and (min-width: 800px) {
//     body.lock {
//         overflow: auto;
//     }
// }
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: $roughwood-grey-dark;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($roughwood-brown, 1);
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content {
  @extend %block;
}
