// // // // // // // // // // //
//
// Filter List
//
// // // // // // // // // // //
.filter-list {
  @extend %default-transition;
  @include flexbox();
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  max-height: 0;
  overflow: hidden;
  padding-right: 20px;
  padding-left: 20px;
  opacity: 0;
  &.size {
    .filter-box {
      display: inline-block;
      width: 48px;
      text-align: center;
    }
  }
  &.expand {
    padding: 20px;
    max-height: 999px;
    opacity: 1;
    &.sizes-letter-list {
      padding-top: 0;
    }
  }
  @include mediaMin($f-collapse) {
    max-height: 9999px;
    padding-top: 20px;
    opacity: 1;
  }
  li {
    margin: 0.5em 1em;
  }
  a {
    color: $roughwood-brown;
    font-family: $font-body;
    font-size: 1.6em;
    font-weight: 500;
  }
  &.colors {
    li {
      @include transition(0.15s);
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: 3px solid transparent;
      &.filter-active {
        border-color: $light;
      }
      a {
        @include position(absolute, 0px 0px 0px 0px);
      }
    }
  }
}
