a.cta,
button.apply,
button.clear,
button.cta {
  font-family: $font-roughwood;
  font-size: 1.6em;
  color: $roughwood-grey;
  background-color: transparent;
  border: 1px solid $roughwood-grey;
  padding: 1em;
  text-align: center;
  @extend %default-transition;
  font-weight: 700;
  letter-spacing: 2px;
  &.dark {
    background-color: $roughwood-brown;
    border-color: $roughwood-brown;
  }
}

.no-touchevents {
  a.cta:hover,
  button.apply:hover,
  button.clear:hover,
  button.cta:hover {
    background-color: $roughwood-grey;
    color: $roughwood-brown;
    &.dark {
      background-color: #c7c4bf;
      color: $roughwood-brown;
    }
  }
}
