.concierge {
	width: 100%;
	float: left;
	&:before,
	&:after {
		content:"";
		width: 100%;
		height: 100vh;
	}
	&:before {
		background-image: url(https://s3.amazonaws.com/files.roughwood.luxury/general/concierge-bg.jpg?mtime=20160531163116);
		background-size: cover;
		background-position: center center;
		@include position(fixed, 0px auto auto 0px);
		z-index: -1;
	}
	&:after {
		background: rgba($dark, 0.4);
		@include position(fixed, 0px auto auto 0px);
		z-index: 0;
	}
	ul {
		width: 100%;
		float: left;
		z-index: 2;
		padding: 0 1em;
		padding-bottom: 6em;
		@include position(absolute, 5em auto auto 50%);
		@include transform(translateX(-50%));
		position: relative;
		max-width: 65em;
	}
	li {
		width: 100%;
		float: left;
		margin: 1em 0;
		padding: 1em;
		position: relative;
	}
	h2 {
		color: $light;
		font-family: $font-roughwood;
		font-size: 2em;
		text-align: left;
		padding: 1em;
		background-color: rgba($roughwood-grey-dark, 0.3);
		cursor: pointer;
		&:after,
		&:before {
			content:"";
			width: 20px;
			height: 2px;
			background: rgba($roughwood-grey, 0.75);
			@include position(absolute, 1.9em 1em auto auto);
			@extend %default-transition;
		}
		&:after {
			@include transform(rotate(90deg));
		}
	}
	h5 {
		font-size: 1.4em;
		background: transparent;
		color: $light;
		font-family: $font-body;
		padding: 1em 0;
		line-height: 2em;
		text-align: left;
	}
	.service {
		width: 100%;
		float: left;
		max-height: 0;
		overflow: hidden;
		@extend %default-transition;
		padding: 0 2em;
		background-color: rgba($roughwood-grey-dark, 0.3);
		p {
			width: 100%;
			float: left;
			color: $light;
			font-size: 1.4em;
			font-family: $font-body;
			line-height: 2em;
			a {
				text-decoration: none;
				color: inherit;
	            position: relative;
	            border-bottom: 1px solid $light;
			}
		}
		blockquote {
			color: $light;
			font-family: $font-body;
			font-style: italic;
			font-size: 1.4em;
			padding: 0 1em;
			line-height: 2em;
		}
	}
	.reveal {
		.service {
			max-height: 100em;
			margin: 1em 0;
			padding: 2em;
			&.sizing {
				max-height: 1000em;
			}
		}
		h2 {
			&:before {
				@include transform(rotate(360deg));
			}
			&:after {
				@include transform(rotate(180deg));
			}
		}
	}
}
@media all and (min-width: 37.5em) {
	.concierge {
		h2 {
			font-size: 2.4em;
			&:before,
			&:after {
				right: 1.5em;
			}
		}
		h5 {
			font-size: 1.8em;
		}
		p {
			font-size: 1.6em;
		}
		.service {
			blockquote {
				padding: 0 3em;
			}
		}
	}
}
