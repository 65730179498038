div.subscribe-container {
  padding: 0 5%;
  input[type='email'] {
    width: 65%;
    border: none;
    margin: 0%;
    padding: 0 0.5em;
    background-color: $roughwood-grey;
    border: 1px solid $roughwood-brown;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    font-family: $font-body;
    color: $roughwood-brown;
    float: left;
    @include placeholder {
      font-family: $font-body;
      font-size: 16px;
      font-style: italic;
      color: rgba($roughwood-brown, 0.4);
    }
  }
  button.cta {
    float: right;
    height: 40px;
    line-height: 10px;
    padding: 0 1em;
  }
  div.klaviyo_messages {
    float: left;
    width: 100%;
    font-family: $font-body;
    font-size: 1.6em;
    margin: 1em 0;
    div.error_message {
      color: $invalid;
    }
    div.success_message {
      text-align: center;
      color: $roughwood-grey;
    }
  }
  label.cta {
    font-family: $font-body;
    color: $roughwood-grey;
    margin: 1em 0;
    font-size: 2.4em;
    text-align: center;
    width: 100%;
    float: left;
    font-style: italic;
  }
}

@media all and (min-width: 41em) {
  div.subscribe-container {
    padding: 0;
  }
}
