.confirmation {
	width: 100%;
	float: left;
	position: relative;
	top: 50px;
	min-height: calc(100vh - 50px);
	h2,h4 {
        font-size: 2.4em;
        color: $roughwood-brown;
        font-family: $font-body;
        padding-left: 2.5%;
        margin-top: 1em;
        width: 100%;
        font-weight: 700;
	}
	h4 {
		font-size: 2em;
		font-weight: 700;
	}
	p {
		float: left;
		font-size: 1.6em;
		color: $roughwood-brown;
		font-family: $font-body;
		padding: 0 2.5%;
		width: 100%;
		margin: 0.5em 0;
		line-height: 2em;
		font-style: italic;
	}
	.summary {
		margin: 0;
		width: 100%;
		h4 {
			padding-left: 0;
		}
	}
	.review {
		padding: 0;
		ul {
			margin-top: 0;
		}
		li p {
			line-height: 1em;
			margin: 0 1%;
		}
		li:first-child {
			margin-top: 0;
		}
	}
	.pricing-container {
		margin: 0 2.5%;
		width: 95%;
		h4 {
			font-size: 1.6em;
		}
	}
}
@media all and (min-width: 40.625em) {
	.confirmation .review li p {
		line-height: 120px;
	}
}
@media all and (min-width: 43.75em) {
	.confirmation .summary {
		width: 60%;
		margin: 0 20%;
		h4 {
			padding-left: 2.5%;
		}
		.pricing-container {
			h4 {
				padding-left: 0;
			}
		}
	}
}
