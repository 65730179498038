// // // // // // // // // // //
//
// About Page
//
// // // // // // // // // // //

main.about {
  width: 100%;
  float: left;
  section.block-rich-text {
    padding: 5em;
    text-align: center;
    p {
      max-width: 750px;
      padding: 0 2.5%;
    }
  }
  section.block-headline {
    padding: 3em 0;
  }
}

.signup-block {
  background-color: #e4e2dd;
  color: rgba(27, 23, 23, 0.45);
  font-family: 'Alegreya', serif;
  font-size: 1.5em;
  width: 100%;
  float: left;
  .signup-form {
    max-width: 450px;
    margin: 0 auto 70px;
    padding: 20px;
    > div {
      position: relative;
      margin: 4em 0;
    }
    .active {
      label {
        @include transform(translateY(-30px));
      }
    }
    label {
      @include transitions(transform 0.2s ease-in-out);
      font-family: 'Alegreya', serif;
      font-size: 18px;
      &:not(.accept) {
        position: absolute;
        left: 10px;
      }
      &.accept {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        height: 50px;
        margin: 1.5em 0;
        span {
          width: 90%;
        }
      }
    }
    input {
      background-color: transparent;
      width: 100%;
      line-height: 30px;
      padding-left: 10px;
      border-bottom: 1px solid $roughwood-brown;
      &[type='submit'] {
        @extend %default-transition;
        background-color: transparent;
        color: #1b1717;
        font-size: 18px;
        width: 100%;
        line-height: 3em;
        margin-top: 2em;
        padding: 0 10px;
        border: 1px solid #1b1717;
        &:hover {
          background-color: #1b1717;
          color: #e4e2dd;
        }
      }
      &[type='checkbox'] {
        @include appearance(none);
        transition: 0.15s;
        position: relative;
        background-color: transparent;
        height: 20px;
        width: 20px;
        // margin: 0;
        // margin-right: 15px;
        // padding: 0;
        float: left;
        cursor: pointer;
        border: 1px solid #1b1717;
        &:checked:after,
        &:checked:before {
          opacity: 1;
        }
        &:before,
        &:after {
          content: '';
          background-color: #1b1717;
          width: 5px;
          height: 20px;
          transition: 0.15s;
          position: absolute;
          top: -3px;
          right: auto;
          bottom: auto;
          left: 13px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          opacity: 0;
        }
        &:after {
          height: 10px;
          position: absolute;
          top: 5px;
          right: auto;
          bottom: auto;
          left: 5px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
    a {
      text-decoration: none;
      color: $roughwood-brown;
    }
  }
}

@media all and (min-width: 500px) {
  main.about {
    section.block-rich-text {
      p {
        padding: 0;
      }
    }
  }
}
