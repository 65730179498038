section.block-two-image {
  width: 100%;
  float: left;
  > div {
    width: 100%;
    padding-bottom: 125%;
    background-size: cover;
    float: left;
    background-repeat: no-repeat;
    position: relative;
  }
  div.cta-container {
    width: 80%;
    max-width: 40em;
    @extend %absolute-middle;
  }
}

@media all and (min-width: 50em) {
  section.block-two-image {
    > div {
      width: 50%;
      padding-bottom: 63%;
    }
    div.cta-container {
      &.extraSmall p {
        font-size: 1.6em;
      }
      &.small p {
        font-size: 1.75em;
      }
      &.medium p {
        font-size: 1.9em;
      }
      &.large p {
        font-size: 2.05em;
      }
      &.extraLarge p {
        font-size: 2.2em;
      }
      &.extraSmall h2 {
        font-size: 4em;
      }
      &.small h2 {
        font-size: 4.4em;
      }
      &.medium h2 {
        font-size: 4.8em;
      }
      &.large h2 {
        font-size: 5.2em;
      }
      &.extraLarge h2 {
        font-size: 5.6em;
      }
    }
  }
}
