// // // // // // // // // // // 
//
// Landing
//
// // // // // // // // // // // 

.login-modal {
    // @extend %default-transition;
    // @include position(fixed, 50px auto auto 50%);
    // border: 10px solid white;
    // @include transform(translateX(-50%) translateY(-400%));
    // background-color: lighten($roughwood-brown, 5%); 
    // width: 100%;
    // max-height: 100vh;
    // padding: 2em 0;
    // z-index: 9999;
    // overflow-y: auto;
    // &.show {
    //     @include transform(translateX(-50%) translateY(0%));
    //     outline: 2000px solid rgba($dark, 0.6);
    // }

    // @media all and (min-width:50em) {
    //     top: 50%;
    //     &.show {
    //         @include transform(translateX(-50%) translateY(-50%));
    //     }
    // }
}
 
// @media all and (min-width: 31.25em) {
//     .login-modal {
//         max-width: 31.25em;
//         padding: 1em 3em;
//         padding-bottom: 3em;
//     }
// }
.request-container {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 5%;
}
.icon-frame-3 {
    @extend %absolute-middle;
    width: 80%;
    max-width: 250px;
    height: 200px;
    use {
        fill: $product-background;
        width: 100%;
        height: 100%;
    }
}
.hide-frame {
    display: none;
}
.icon-roughwood-logo {
    width: 90%;
    height: 100px;
    margin: 0 5%;
    span {
        position: relative;
    }
    use {
        fill: $roughwood-grey-dark;
    }
}
.logo-wrapper {
    position: relative;
    h1 {
        width: 100%;
        color: $roughwood-grey-dark;
        font-family: $font-roughwood;
        font-size: 24px;
        padding: 5px 0;
        text-align: center;
    }
}
.landing {
    .icon-line-8 {
        width: 80%;
        max-width: 450px;
        margin: 0 auto;
        display: block;
        height: 60px;
        use {
            fill: $product-background;
            width: 100%;
            height: 100%;
        }
    }
}
// .signin-form {
//     width: 100%;
//     display: block;
//     margin: 0 auto;
//     max-width: 300px;
//     position: relative;
//     padding-top: 30px;
//     z-index: 10;
//     h2 {
//         width: 100%;
//         color: $roughwood-grey-dark;
//         font-size: 20px;
//         font-family: $font-body;
//         text-align: center;
//     }
    // input {
    //     display: block;
    //     background: $product-background;
    //     color: $roughwood-grey-dark;
    //     font-size: 18px;
    //     font-family: $font-body;
    //     width: 100%;
    //     line-height: 36px;
    //     margin: 15px 0;
    //     padding: 0 10px;
    //     @include placeholder {
    //         font-style: italic;
    //         font-size: 16px;
    //         color: $roughwood-grey;
    //     }
    // }
//     .register-input {
//         margin-top: 30px;
//     }
//     label {
//         color: $roughwood-grey;
//         font-family: $font-body;
//         font-size: 18px;
//         width: 100%;
//         line-height: 20px;
//         margin: 15px 0;
//         padding-right: 10px;
//         padding-left: 0px;
//         float: left;
        // &.accept {
        //     font-size: 14px;
        //     margin: 1.5em 0;
        //     input {
        //         margin: 20px 0;
        //         margin-right: 20px;
        //     }
        // }
        // a,span {
        //     color: $roughwood-grey;
        //     text-decoration: none;
        //     position: relative;
        //     cursor: pointer;
        //     border-bottom: 1px solid $light;
        // }
        // &.clickable {
        //     margin: 0px;
        //     font-size: 1.5em;
        // }
//     }
    // p {
    //     color: $invalid;
    //     background: $product-background;
    //     font-size: 2em;
    //     font-family: $font-body;
    //     width: 100%;
    //     padding: 0.5em 0;
    //     text-align: center;
    // }
    // input[type="checkbox"] {
    //     @include appearance(none);
    //     @include transition(0.15s);
    //     background-color: $product-background;
    //     position: relative;
    //     height: 20px;
    //     width: 20px;
    //     margin: 0;
    //     margin-right: 15px;
    //     padding: 0;
    //     float: left;
    //     &:after,
    //     &:before {
    //         @include transition(0.15s);
    //         @include position(absolute, -3px auto auto 13px);
    //         @include transform(rotate(45deg));
    //         content: "";
    //         background-color: $roughwood-grey;
    //         width: 5px;
    //         height: 20px;
    //         opacity: 0;
    //     }
    //     &:after {
    //         @include position(absolute, 5px auto auto 5px);
    //         @include transform(rotate(-45deg));
    //         height: 10px;
    //     }
    // }
    // input[type="checkbox"]:checked {
    //     &:after,
    //     &:before {
    //         opacity: 1;
    //     }
    // }
//     .entry {
//         display: block;
//         color: $roughwood-grey-dark;
//         background: transparent;
//         font-family: $font-body;
//         font-size: 18px;
//         width: 100%;
//         line-height: 28px;
//         margin: 15px 0;
//         border: 2px solid $product-background;
//     }
//     .errors {
//         background: $product-background;
//         width: 100%;
//         z-index: 10;
//         li {
//             color: $invalid;
//             background: $product-background;
//             font-size: 2em;
//             font-family: $font-body;
//             width: 100%;
//             padding: 0.25em 0;
//             margin: 1em 0;
//             text-align: center;
//         }
//     }
// }
// .signUp {
//     padding-top: 20px;
//     padding-bottom: 40px;
//     input,
//     label {
//         margin: 5px 0;
//     }
// }
.hide {
    display: none;
}


@media all and (min-width: 500px) {
    .logo-wrapper {
        &:after {
            bottom: 0px;
        }
        h1 {
            bottom: -45px;
        }
    }
}
@media all and (min-width: 700px) {
    .landing {
        blockquote {
            font-size: 24px;
            line-height: 36px;
        }
    }
    .icon-roughwood-logo {
        width: 100%;
        margin: 7.5% 0px 0px 0px;
    }
    .logo-wrapper {
        h1 {
            bottom: -80px;
        }
    }
}
@media all and (min-width: 800px) {
    .landing {
        blockquote {
            padding: 0;
            margin-top: 5%;
        }
    }
}
