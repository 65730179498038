
.validation {
    color: red;
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 12px;
}
.create-profile {
    background: #c7c4bf;
    width: 100%;
    padding-top: 24px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;
    margin: 0px auto 0px;

    input[type="checkbox"] {
        @include transition(0.15s);
        @include appearance(none);
        position: relative;
        background-color: $product-background;
        height: 20px;
        width: 20px;
        margin: 0;
        margin-right: 15px;
        padding: 0;
        float: left;
        &:after,
        &:before {
            @include transition(0.15s);
            @include position(absolute, -3px auto auto 13px);
            @include transform(rotate(45deg));
            content: "";
            background-color: $roughwood-grey;
            width: 5px;
            height: 20px;
            opacity: 0;
        }
        &:after {
            @include position(absolute, 5px auto auto 5px);
            @include transform(rotate(-45deg));
            height: 10px;
        }
    }
    input[type="checkbox"]:checked {
        &:after,
        &:before {
            opacity: 1;
        }
    }
    .create-profile-status {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .row {
        position: relative;
        max-width: 1250px;
        margin: 0px auto;
    }
    .controls-container {
        padding-top: 24px;
        padding-bottom: 36px;
    }
    .select {
        position: relative;
        &:after {
            @include position(absolute, auto 2.5% 10px auto);
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 15px solid $roughwood-brown;
        }
        select {
            @include appearance(none);
            background-color: $roughwood-grey;
            color: $roughwood-brown;
            font-size: 16px;
            width: 100%;
            height: 2.5em;
            padding: 0 0.5em;
            border-radius: 0;
        }
    }
    button {
        background-color:$roughwood-brown;
        color: $roughwood-grey;
        padding: 16px 20px;
        font-size: 18px;
        font-family: $font-roughwood;
        font-weight: 900;
        width: 150px;
        margin-right: 11px;
        position: relative;
        span {
            margin: 0px 10px;
        }
        &.inactive {
            background: #aaa7a3;
        }    
        &.prev svg {
            @include transform(rotate(180deg));
        }    
        svg {
            display: inline;
            width: 14px;
            height: 14px;
            * {
                fill: white;
            }
        }
    }
    h2 {
        font-size: 44px;
        color: $roughwood-brown;
        padding-top: 10px;
        span {
            color: $roughwood-grey-dark;
            font-size: 22px;
        }
    }
    .button {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $roughwood-brown;
        color: $roughwood-grey;
        padding: 16px 20px;
        font-size: 18px;
        font-family: "Fondamento";
        font-weight: 900;
    }
}

.create-profile-page {
    @include transition(0.3s);
    display: none;
    &.active {
        display: block;
    }
    .row:after {
        clear: both;
        content: '';
        display: block;
    }
    .half {
        width: calc(50% - 20px);
        float: left;
        margin-left: 20px;
        &:nth-child(2){
            margin-right: 20px;
            margin-left: 0px;
        }
        &:nth-child(1){
            margin-left: 20px;
        }
    }
    .third-row {
        width: 180px;
        margin-bottom: 24px;
        margin-right: 48px;
        float: left;
    }
    label {
        display: block;
        font-size: 15px;
        font-family: "Fondamento";
        padding: 0px 0px 10px;      
    }
    input[type="text"]{
        background: #e5e2dc;
        display: block;
        font-size: 15px;
        padding: 16px;
    }
    .interests label {
        width: 33% !important;
        padding: 12px 0px !important;
        float: left;
    }
    .interests:after {
        clear: both;
        display: block;
        content: '';
    }
    .body-types {
        display: inline-block;
        background: #54534f;
        padding: 12px;
        img {
            @include transition(0.5s);
            padding: 0;
            margin: 0;
            float: left;
            cursor: pointer;
            border: 2px solid #545250;
            &:hover {
                border-color: #adadab;
            }
            &.selected {
                border-color: #e5e2dc;
            }
        }
    }
    .body-type-buttons {
        display: none;
    }
    h2.thank-you {
        text-align:center;
    } 
    .thank-you p {
        font-size: 22px;
        margin-bottom: 22px;
        max-width: 767px;
        text-align: center;
        margin: 0 auto 22px;
    }

    .radio {
        @include appearance(none);
        @include transition(50ms);
        @include transform(translate3d(0,0,0));
        background-color: #E4E2DD;
        height: 20px;
        width: 20px;
        margin: 0px 5%;
        border-radius: 50%;
        &:checked {
            background-color: #E4E2DD;
            border: 7px solid #1b1717;
        }
    }
    .radio-buttons {
        font-family: "Fondamento";
        font-size: 18px;
        padding: 0px 0px 10px;
    }
    .full-row {
        margin-bottom: 12px;
        &.other {
            display: none;
        }
        &.charities {
            display: none;
        }
    }
    &:after {
        content: '';
        clear: both;
        display: block;
    }
    .three-up {
        width: 33%;
        float: left;
        list-style: none;
    }
    .black {
        color: rgba(27,23,23,.9);
    }
    .no-bottom {
        padding: 26px 12px 0px;
        .cbox {
            margin-top: 12px !important;
        }
        span {
            display: inline-block;
            width: 100%;
            min-width: 88px;
            text-align: left;
        }
        .inline {
            display: inline !important;
            width: auto !important;
            margin-bottom: -15px !important;
        }
        .hidden {
            visibility: hidden;
        }
    }
    .full {
        width: 100%;
    }

    label {
        display: block;
        font-family: "Fondamento";
        font-size: 15px;
        width: 100%;
        padding: 12px 12px 12px 0px;
        .radio {

        }
    }
    .full-row input[type=text] {
        display: block;
        background: #e5e2dd;
        font-family: "Fondamento";
        font-size: 15px;
        width: 100%;
        padding: 12px;
        border: none;
    }
}

.drop-padding {
    padding: 12px;
    background: white;
}
.dropzone .dz-message {
    padding-bottom: 0;
    margin-bottom: 0;
}
.dz-default {
    h3 {
        color: #c6c3be;
        font-size: 36px;
        padding: 0;
        margin: 0;
        margin-top: 12px;
    }
    p {
        color: #c6c3be;
        text-align: center;
        margin: 0;
        padding: 0;
    }
    .link-button {
        display: inline-block;
        position: relative;
        color: $roughwood-brown;
        font-size: 18px;
        font-weight: 500;
        width: auto;
        padding-top: 32px;
    }
    .link-button:after {
        content: ">";
        position: absolute;
        right: -24px;
        top: 32px;
    }
}

.profile-image-container {
    background: #e4e3e0;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    .profile-image {
        width: 200px;
        height: 200px;
    }
}
.create-profile {
    .create-profile-status {
        position: relative;
        margin-top: 75px;
        margin-bottom: 36px;
        padding-bottom: 26px;
        border-bottom: 1px solid #bfbcb7;
    }
    .create-profile-status:after {
        content: '';
        clear: both;
        display: block;
    }
    .create-profile-status {
        .status-bar {
            display: block;
            position: absolute;
            min-height: 8px;
            top: 16px;
            left: 7%;
        }
        .status-bar.empty {
            background: #e5e2dc;
            width: 86%;
        }
        .status-bar.done {
            background: $roughwood-brown;
            width: 0%;
            top: 16px;
            left: 7%;
        }

        .diamond {
            @include transform(rotate(45deg));
            @include transition(0.5s);
            display: block;
            color: #c6c3be;
            background: #e5e2dc;
            width: 40px;
            height: 40px;
            margin: 0 auto;
            border: 4px solid #e5e2dc;
            &.active {
                color: white;
                background: $roughwood-brown;
                border: 4px solid white;
            }
            &.done {
                background: $roughwood-brown;
                border: 4px solid $roughwood-brown;
                .diamond-number {
                    opacity:0;
                }
                .diamond-done {
                    opacity: 1;
                }
            }

            .diamond-done {
                @include transition(0.5s);
                position: absolute;
                top: 6px;
                left: 6px;
                opacity: 0;
                svg {
                    @include transform(rotate(-45deg));
                }
                path {
                    fill: white !important;
                }
            
            }
            .diamond-numbe {
                @include transform(rotate(-45deg));
                font-family: "Fondamento";
                font-size: 20px;
                font-weight: 900;
                display: block;
                width: 100%;
                height: 100%;
                text-align: center;
                padding-top: 2px;
            }
        }

        .status-step {
            width: calc(100%/8);
            margin-bottom: 24px;
            float: left;
            .title {
                display: block;
                color: white;
                font-family: "Fondamento";
                font-size: 15px;
                margin-top: 13px;
                text-align: center;
            }
        }
    }
}

@media(max-width: 767px){
    .create-profile {
        .create-profile-status {
            position: relative;
            margin-top: 50px;
            margin-bottom: 10px;
            padding-bottom: 6px;
            border-bottom: 1px solid #bfbcb7;
            .status-step .title{
                display: none;
            }
            .status-step .diamond {
                width: 16px;
                height: 16px;
            }
            .status-step .diamond .diamond-number {
                display: none;
            }
            .status-step .diamond .diamond-done {
                display: none;
            }
            .status-bar {
                top: 5px;
                min-height: 6px;
            }
            .status-bar.done {
                top: 5px;
                min-height: 6px;
            }
        }
        h2 {
            font-size: 32px;
        }
        .controls-container button {
            position: relative;
            background-color: $roughwood-brown;
            color: $roughwood-grey;
            font-size: 18px;
            font-family: "Fondamento";
            font-weight: 900;
            width: calc(50% - 7px);
            margin-right: 11px;
            padding: 12px 0px;
            &.next {
                margin-right: 0px;
            }
        }
    }

    .create-profile-page {
        .full-row select, .create-profile-page .full-row input[type=text] {
            width: 100%;
            border-radius: 2px;
        }
        .half {
            width: 100%;
            margin: 0 !important;
            float: none;
            label {
                padding: 12px 0;
            }
        }
        .third-row input[type=text], .third-row {
            width: 100%;
        }
    }
}
@media(max-width: 450px){
    .create-profile-page .body-types img {
        width: 33%;
    }
}
 
.row.thank-you {
    a.cta {
        display: block;
        max-width: 150px;
        margin: 2em auto;
    }
}