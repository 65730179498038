h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-roughwood;
  color: $light;
}
p {
  font-family: $font-body;
  color: $light;
  margin: 0;
}
