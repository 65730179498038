section.block-featured-products {
  width: 100%;
  float: left;
  background: transparent;
  position: relative;
  padding: 1.5em 0;
  h2 {
    font-size: 28px;
    font-family: $font-roughwood;
    color: $light;
    width: 100%;
    text-align: center;
    @include position(absolute, auto auto 17.5% auto);
  }
  h3 {
    font-size: 1.6em;
    font-family: $font-body;
    color: $roughwood-brown;
    width: 100%;
    text-align: center;
    @include position(absolute, auto auto 12.5% auto);
  }
  .slick-slider {
    float: left;
    height: 400px;
    width: 100%;
    position: relative;
    .slick-slide {
      height: 400px;
      position: relative;
      cursor: pointer;
      img {
        height: 90%;
        width: auto;
        float: left;
        @include position(relative, auto auto auto 50%);
        @include transform(translateX(-50%));
      }
      .name {
        @include position(absolute, 5px auto auto 12.5%);
        font-size: 20px;
        color: $roughwood-grey;
        font-family: $font-body;
        opacity: 0;
        @extend %default-transition;
        z-index: 10;
      }
      .price {
        font-size: 14px;
        color: $roughwood-grey;
        font-family: $font-body;
        opacity: 0;
        @extend %default-transition;
        @include position(absolute, 30px auto auto 12.5%);
        z-index: 10;
      }
      .sale-price {
        text-decoration: line-through;
      }
    }
    .slick-next,
    .slick-prev {
      height: 30px;
      width: 60px;
      border-radius: 90px 90px 0 0;
      background: transparent;
      z-index: 100;
      @extend %default-transition;
      &:before {
        content: '';
        width: 2px;
        height: 20px;
        opacity: 1;
        background: $roughwood-brown;
        @include position(absolute, 50% auto auto 33%);
        @include transform(translateY(-50%) rotate(45deg));
        @extend %default-transition;
      }
      &:after {
        content: '';
        width: 2px;
        height: 20px;
        background: $roughwood-brown;
        @include position(absolute, auto 40% 50% auto);
        @include transform(translateY(50%) rotate(-45deg));
        @extend %default-transition;
      }
    }
    .slick-next {
      @include position(absolute, -6px 5px auto auto);
      @include transform(translateY(-50%) rotate(90deg));
    }
    .slick-prev {
      @include position(absolute, -10px 40px auto auto);
      @include transform(translateY(-50%) rotate(270deg));
    }
    .slick-dots {
      bottom: -30px;
      button {
        &:before {
          content: '\2666 \FE0E';
          font-size: 14px;
          color: $roughwood-grey;
          opacity: 1;
        }
      }
      .slick-active {
        button {
          &:before {
            color: $roughwood-brown;
            opacity: 1;
          }
        }
      }
    }
  }
}

.no-touchevents {
}
