section.block-rich-text {
  width: 100%;
  float: left;
  padding: 1.5em 0;
  p {
    font-size: 1.4em;
    line-height: 1.8em;
    padding: 0 5%;
    max-width: 800px;
    margin: 0 auto;
  }
}
@media all and (min-width: 46.875em) {
  section.block-rich-text {
    p {
      font-size: 1.6em;
    }
  }
}

@media all and (min-width: 64em) {
  section.block-rich-text {
    &.extraSmall p {
      font-size: 1.6em;
    }
    &.small p {
      font-size: 1.75em;
    }
    &.medium p {
      font-size: 1.9em;
    }
    &.large p {
      font-size: 2.05em;
    }
    &.extraLarge p {
      font-size: 2.2em;
    }
  }
}
