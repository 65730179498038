.form-container .expiration .select {
	width: 100%;
	margin: 1em 0;
	padding: 0 5%;
	&:after {
		right: 7.5%;
	}
	label {
		width: 100%;
	}
}
.error-message {
    color: $invalid;
    padding: 0.5em 5%;
    width: 100%;
    font-size: 1.6em;
    font-family: $font-body;
    float: left;
    text-align: left;
}
.card-errors {
	width: 100%;
	float: left;
}

.shipping-method {
	> p {
		font-size: 2em;
		color: $roughwood-brown;
		padding-left: 0.5em;
		margin-bottom: 1em;
	}
	label {
		display: flex;
		align-items: center;
		font-family: $font-body;
		strong {
			margin-right: 2em;
		}
	}
}

@media all and (min-width: 37.5em) {
	.form-container .name-container {
		width: 50%;
		padding: 0 2.75%;
	}

	.form-container .expiration .select,
	.form-container .cvv {
		label {
			margin: 10px 0;
		}
		width: 33.333333%;
	}
	.card-errors {
		.error-message {
			width: 33.3333333%;
			font-size: 1.4em;
			margin: 1em 0;
		}
	}
	.form-container .expiration .select {
		margin: 0;
		&:after {
			right: 17.5%;
		}
	}
	.form-container .cvv {
		padding: 0 5%;
		input {
			margin: 0 5%;
		}
	}
}
