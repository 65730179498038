section.block-full-image {
  width: 100%;
  float: left;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center center;
  &:after {
    content: '';
    display: block;
    background-color: rgba($dark, 0.15);
    @include position(absolute, 0px 0px 0px 0px);
    z-index: 2;
  }
  div.cta-container {
    opacity: 1;
    z-index: 10;
  }
}
.no-touchevents {
  section.block-full-image {
    background-attachment: fixed;
  }
}

@media all and (min-width: 64em) {
  section.block-full-image {
    div.cta-container {
      &.extraSmall p {
        font-size: 1.6em;
      }
      &.small p {
        font-size: 1.75em;
      }
      &.medium p {
        font-size: 1.9em;
      }
      &.large p {
        font-size: 2.05em;
      }
      &.extraLarge p {
        font-size: 2.2em;
      }
    }
  }
}
