.progress-container {
	width: 100%;
	float: left;
	padding: 100px 2.5%;
	padding-bottom: 10px;
	ul	{
		width: 95%;
		margin: 0 2.5%;
		float: left;
		a {
			text-decoration: none;
			color: inherit;
		}
		li {
			width: 33.333333%;
			text-align: right;
			float: left;
			font-size: 18px;
			line-height: 22px;
			padding: 4px 0;
			font-family: $font-roughwood;
		}
	}
}

.bar {
	width: 95%;
	height: 20px;
	margin: 0 2.5%;
	float: left;
	background: rgba($roughwood-brown, 0.15);
	position: relative;
	border-radius:10px;
	overflow: hidden;
}
.progress {
	width: 33.333333%;
	height: 20px;
	background: $roughwood-brown;
}
.payment {
	width: 66.666666%;
}
.confirm {
	width: 100%;
}
.confirm-button {
	display: block;
	margin: 30px 0;
	float: left;
	background: $roughwood-brown;
	color: $light;
	font-size: 16px;
	font-family: $font-roughwood;
	padding: 15px 2.5%;
	@include position(relative, auto auto auto 50%);
	@include transform(translateX(-50%));
}
@media all and (min-width: 800px) {
	.progress-container {
		padding: 100px 10%;
		padding-bottom: 50px;
		ul {
			width: 80%;
			margin: 0 10%;
		}
	}
	.bar {
		width: 80%;
		margin: 0 10%;
	}
}
