section.block-single-cta {
  width: 100%;
  float: left;
  padding: 1.5em 0;
  a.cta {
    color: $roughwood-brown;
    width: 80%;
    max-width: 200px;
    height: 60px;
    margin: 0 auto;
    display: block;
  }
}

@media all and (min-width: 50em) {
  section.block-single-cta {
    padding: 5em 0;
    padding-top: 0;
  }
}
