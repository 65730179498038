.orders {
	min-height: 100vh;
	.review .no-item p {
		width: 100%;
		text-align: center;
		line-height: 1.25em;
	}
	.item {
		position: relative;
	}
	.confirm-button {
		@include position(absolute, 50% 5% auto auto);
		@include transform(translateY(-50%));
		margin: 0;
	}
}
