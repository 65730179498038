// // // // // // // // // // //
//
// Tiles
//
// // // // // // // // // // //

.tiles {
  width: 100%;
  float: left;
  margin-top: 50px;
}
.tile {
  @extend %default-transition;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  float: left;
  &:after {
    @extend %default-transition;
    @include position(absolute, 0px 0px 0px 0px);
    content: '';
    background: rgba($dark, 0.25);
    opacity: 0;
  }
  &:before {
    @extend %default-transition;
    @extend %absolute-middle;
    content: '';
    width: 90%;
    height: 90%;
    opacity: 0;
    z-index: 1;
    border: 2px solid $light;
  }
  h2 {
    @extend %default-transition;
    @extend %absolute-middle;
    font-family: $font-roughwood;
    font-size: 36px;
    color: $roughwood-grey;
    width: 100%;
    text-align: center;
    margin: 0;
    opacity: 0;
    z-index: 1;
  }
}
.no-touchevents {
  .tile:hover {
    &:after {
      opacity: 1;
    }
    &:before {
      opacity: 1;
    }
    h2 {
      opacity: 1;
    }
  }
}
.touchevents {
  .tile {
    &:after {
      opacity: 0.75;
    }
    &:before {
      opacity: 0.75;
    }
    h2 {
      opacity: 1;
    }
  }
}
@media all and (min-width: 500px) {
  .tile {
    width: 50%;
    padding-bottom: 50%;
  }
}
@media all and (min-width: 800px) {
  .tile {
    width: 33.3333333%;
    padding-bottom: 33.3333333%;
  }
}
